import Std from "../Std";
import EditorJS  from "@editorjs/editorjs";

import VariableBlock    from "./VariableBlock";
import SpacerBlock      from "./SpacerBlock";
import HLineBlock       from "./HLineBlock";
import ParagraphBlock   from "./ParagraphBlock";
import Base64ImageBlock from "./Base64ImageBlock";

import list        from "@editorjs/list";
import header      from "@editorjs/header"
import simpleImage from "@editorjs/simple-image"

import {LayoutThreeColumns} from "../StringIcons";

class ColumnsBlock {
	constructor({data}) {
		this.data        = data;
		this.columnCount = data?.columnCount ? data.columnCount : 2;
		this.columns     = data?.columns ? data.columns : [{}, {}];
		this.block       = null;
		this.input       = null;
		this.range       = null;
		this.editors     = [];
	}


	render() {
		this.block = Std.CreateElement(`
			<section class="grid block-columns" style="grid-template-columns: repeat(${this.columnCount}, 1fr)">
			</section>
		`);
		this.createColumns();

		return this.block;
	}

	createColumns() {
		this.block.innerHTML = "";

		for (let i = 0; i < this.columnCount; i++) {
			const id = ColumnsBlock.Id++;
			this.block.append(Std.CreateElement(`<section id="editor${id}" class="editor-column"></section>`));
			this.editors.push(new EditorJS({
				holderId : `editor${id}`,
				data     : this.columns[i],
				tools    : {
					list : {
						class : list,
						inlineToolbar : ["variable"]
					},
					paragraph : {
						class         : ParagraphBlock,
						inlineToolbar : ["variable"]
					},
					header : {
						class         : header,
						inlineToolbar : ["variable"]
					},
					image   : Base64ImageBlock,
					spacer  : SpacerBlock,
					hline   : HLineBlock,
					simpleImage,
					variable : VariableBlock
				}
			}));
		}
	}

	renderSettings() {
		this.input = Std.CreateElement(`
				<input class="form-control" type="number" step="5" value="${this.columnCount}" readonly />
		`);
		this.range = Std.CreateElement(`
			<input type="range" class="form-range" id="colCount" max="5" step="1" min="2" value="${this.columnCount}" />
		`);
		this.range.addEventListener("change", event => {
			this.columnCount = parseInt(event.target.value);
			this.block.style.gridTemplateColumns = `repeat(${this.columnCount}, 1fr)`;
			this.createColumns();
		});
		this.range.addEventListener("input", event => {
			this.input.value = event.target.value;
		});
		const ui = Std.CreateElement(`
			<article class="block-columns-settings">
				<label for="colCount" class="form-label">Anzahl Spalten</label>
			</article>
		`);

		ui.append(this.range);
		ui.append(this.input);

		return ui;
	}

	async save() {
		let columns = [];
		for (let editor of this.editors) {
			const data = await editor.save();
			columns.push(data);
		}
		return {
			columnCount : this.columnCount,
			columns     : columns
		}
	}

	static get toolbox() {
		return {
			title : "Spalten",
			icon  : LayoutThreeColumns
		}
	}

	static get sanitize() {
		return {
			var : true
		};
	}
}
ColumnsBlock.Id = 0;

export default ColumnsBlock;
