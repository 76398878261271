import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";

import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";

import Toggle       from "components/shared/Toggle";


const CourseEdit = ({ currentCourse, show, setShow, onSave }) => {
	const alert = useAlert();
	const [formData, setFormData] = useState(currentCourse === null ? {} : currentCourse);
	const form = useRef();

	useEffect(() => {
		setFormData(currentCourse === null ? {
			name: "",
			year: new Date().getFullYear(),
			active: 0,
		} : { ...currentCourse }
		);
	}, [currentCourse]);

	const handleInput = event => {
		console.log(event);
		const oldData = { ...formData };
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		oldData[event.target.name] = value;
		setFormData(oldData);
	}

	const save = useCallback(event => {
		event.preventDefault();

		if (currentCourse === null) {
			axios.post("/courses", formData).then(response => {
				alert.success(`Der Kurs '${formData.name} [${formData.year}]' wurde erfolgreich angelegt.`);
				form.current.reset();
				setShow(false);
				onSave();
			}).catch(error => alert.error(`Beim Anlegen ist ein Fehler aufgetreten: ${error}`));
		} else {
			axios.put(`/courses/${currentCourse.id}`, formData).then(response => {
				alert.success(`Der Kurs '${formData.name} [${formData.year}]' wurde erfolgreich bearbeitet.`);
				form.current.reset();
				setShow(false);
				onSave();
			}).catch(error => alert.error(`Beim Speichern ist ein Fehler augetreten: ${error}`));
		}
	}, [form, onSave, currentCourse, formData, alert, setShow]);

	return (
		<Modal size="lg" scrollable={true} show={show}>
			<form onSubmit={save} ref={form}>
				<Modal.Header>
					<Modal.Title>
						{currentCourse === null ? "Kurs anlegen" : "Kurs bearbeiten"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<section className="row">
						<section className="col-md-3">Bezeichnung</section>
						<section className="col-md-9">
							<input className="form-control" onChange={handleInput} type="text" name="name" value={formData.name} required />
						</section>
					</section>

					<section className="row">
						<section className="col-md-3">Jahr</section>
						<section className="col-md-9">
							<input className="form-control" type="number" step={1} name="year" onChange={handleInput} max={9999} min={1980} value={formData.year} required />
						</section>
					</section>

					<section className="row">
						<section className="col-md-3">Aktiv</section>
						<section className="col-md-9">
							<Toggle name="active" onChange={handleInput} checked={formData.active} label="" id="active" />
						</section>
					</section>

				</Modal.Body>
				<Modal.Footer>
					<span className="btn btn-outline-danger" onClick={() => {
						form.current.reset();
						setShow(false);
					}}>Abbrechen</span>
					<button className="btn btn-success">Speichern</button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default CourseEdit;
