import React, {useState} from "react";

import {Modal} from "react-bootstrap";

import Toggle         from "./shared/Toggle";
import YesNoModal     from "./shared/YesNoModal";
import ClientSettings from "./ClientSettings";

const ClientSettingsModal = ({show, setShow, cSettings, changeClientSettings}) => {
	const [clientSettings, setClientSettings] = cSettings;
	const [showReset, setShowReset]           = useState(false);

	const handleInput = event => {
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		changeClientSettings(event.target.name, value);
	}

	const reset = () => {
		setClientSettings(new ClientSettings());
	}

	return (
		<Modal show={show} size="lg" onHide={() => {}}>
			<Modal.Header>
				<Modal.Title>Einstellungen</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="row">
					<section className="grid thirty-seventy form">
						<label>Dunkles Thema</label>
						<Toggle name="darkTheme" onChange={handleInput} checked={clientSettings.darkTheme} label="" id="active" />
					</section>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-danger position-absolute" style={{left : 10}} onClick={() => setShowReset(true)}>auf Standard zurücksetzen</button>
				<button className="btn btn-primary" onClick={() => setShow(false)}>Schließen</button>
			</Modal.Footer>
			<YesNoModal show={showReset} setShow={setShowReset} title="Einstellungen zurücksetzen!" text="Wirklich alle Clienteinstellungen zurücksetzen?" callback={reset} />
		</Modal>
	)
};

export default ClientSettingsModal;
