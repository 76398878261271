import PDF, {Align, PDFImage} from "../PDF";

import VariableStore from "../VariableStore";
import DateFormatter from "../../formatters/DateFormatter";

const BlockType = {
	PARAGRAPH : "paragraph",
	HEADER    : "header",
	LIST      : "list",
	SPACER    : "spacer",
	HLINE     : "hline",
	COLUMNS   : "columns",
	TABULAR   : "tabular",
	IMAGE     : "image"
};

const ExampleTabularData = [
	{
		date : "1970-01-01",
		description : "Lorem ipsum dolor sit amet",
		chief_firstname : "Max",
		chief_lastname  : "Mustermann",
		duration_minutes : 120
	},
	{
		date : "1970-03-02",
		description : "Lorem ipsum dolor sit amet",
		chief_firstname : "Max",
		chief_lastname  : "Mustermann",
		duration_minutes : 60
	},
	{
		date : "1970-05-03",
		description : "Lorem ipsum dolor sit amet",
		chief_firstname : "Max",
		chief_lastname  : "Mustermann",
		duration_minutes : 480
	},
	{
		date : "1970-07-04",
		description : "Lorem ipsum dolor sit amet",
		chief_firstname : "Max",
		chief_lastname  : "Mustermann",
		duration_minutes : 300
	}
];
const HoursSum = ExampleTabularData.reduce((accumulator, item) => accumulator + item.duration_minutes, 0);
const ExampleTabular = {
	columnStyles : {
		0 : {
			cellWidth : 30
		},
		2 : {
			cellWidth : 30
		}
	},
	head : [[
		"Datum",
		"Beschreibung",
		"Name",
		"Stunden"
	]],
	body : ExampleTabularData.map(item => {
		return [
			DateFormatter(item.date),
			`${item.description}`,
			item.chief_firstname ?
				`${item.chief_firstname} ${item.chief_lastname}` :
				`${item.user.firstname} ${item.user.lastname}`,
			{
				content : `${parseInt(item.duration_minutes / 60)}h ${item.duration_minutes % 60}m`,
				styles  : {
					halign : "right"
				}
			}
		];
	}),
	foot : [
		[
			{
				content : "Summe",
				colSpan : 3,
			},
			{
				content : `${parseInt(HoursSum / 60)}h ${HoursSum % 60}m`,
				styles  : {
					halign : "right"
				}
			}
		]
	],
	styles : {
		fontSize: 8
	},
	pageBreak : "auto"
};

const ReplaceVariables = (text, variables) => {
	const varPool = variables.map(variable => variable.id);
	return  text.replace(
		/<var class="block-variable" data-id="([a-zA-Z-]+)" ?(style="display: inline;")?>.*?<\/var>/g,
		(match, p1) => {
			return varPool.includes(p1) ?
				variables.filter(variable => variable.id === p1)[0].value : p1
		}
	);
};

const PDFRenderer = (template, editorJSData, variables = VariableStore().variables, tabulardata = ExampleTabular) => {
	const doc = new PDF({
		top    : parseInt(template.ptop),
		right  : parseInt(template.pright),
		bottom : parseInt(template.pbottom),
		left   : parseInt(template.pleft)
	});
	doc.pagination = template.pagination;

	const render = source => {
		for (const block of source.blocks) {
			console.log(block);
			switch (block.type) {
				default:
					continue;
				case BlockType.HEADER:
					const alignment = block.data.alignment === "center" ?
						Align.CENTER :
						(block.data.alignment === "right" ? Align.RIGHT : Align.LEFT);
					const text = ReplaceVariables(block.data.text, variables);
					switch (block.data.level) {
						default:
						case 1:
							doc.h1(text, alignment);
							break;
						case 2:
							doc.h2(text, alignment);
							break;
						case 3:
							doc.h3(text, alignment);
							break;
					}
					break;
				case BlockType.PARAGRAPH:
					const fontSize = parseInt(block.data.fontSize);
					const vspace   = parseInt(block.data.spacing);
					doc.write(ReplaceVariables(block.data.text, variables), fontSize, vspace);
					break;
				case BlockType.LIST:
					const items = block.data.items.map(item => ReplaceVariables(item, variables));

					if (block.data.style === "ordered")
						doc.orderedList(items);
					else
						doc.list(items);
					break;
				case BlockType.SPACER:
					doc.vspace(parseInt(block.data.height));
					break;
				case BlockType.HLINE:
					doc.hline();
					break;
				case BlockType.COLUMNS:
					doc.startColumns(block.data.columnCount);
					for (const column of block.data.columns) {
						render(column);
						doc.nextColumn();
					}
					doc.endColumns();
					break;
				case BlockType.TABULAR:
					doc.vspace(5).tabular(tabulardata).vspace(5);
					break;
				case BlockType.IMAGE:
					if (!block.data.image || typeof block.data.image !== "string")
						continue;
					const align = block.data.alignment === "center" ?
						Align.CENTER :
						(block.data.alignment === "right" ? Align.RIGHT : Align.BLOCK);
					doc.image(new PDFImage(block.data.image, parseInt(block.data.width), parseInt(block.data.height)), 0, align);
					break;
			}
		}
	};

	render(editorJSData);

	if (template.index)
		doc.makeIndex(2);

	return doc;
}

export default PDFRenderer;
