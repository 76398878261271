import {LightningFill} from "../StringIcons";
import Std from "../Std";

import VariableStore from "../VariableStore";

class VariableBlock {
	constructor({api}) {
		this.api    = api;
		this.button = null;
		this.state  = false;
		this.value  = null;
		this.select = null;
	}

	render() {
		this.button = Std.CreateElement(`
			<button class="${this.api.styles.inlineToolButton}">${LightningFill}</button>
		`);

		return this.button;
	}

	renderActions() {
		this.select = Std.CreateElement(`
			<select class="block-variable">
				<option disabled selected>Variable auswählen</option>
			${Object.keys(VariableStore().groupedVariables).map(groupName => `
				<optgroup label="${groupName}">
					${VariableStore().groupedVariables[groupName].map(item => `<option value="${item.id}" ${this.value === item.id ? "selected" : ""}>${item.label}</option>`)}				
				</optgroup>
			`)}
			</select>
		`);
		this.select.addEventListener("change", event => {
			this.value = event.target.value;
			if (this.var)
				this.var.dataset.id = this.value;
		});

		return this.select;
	}

	wrap(range) {
		this.state = true;
		const text = range.extractContents();
		const elm = Std.CreateElement(`
			<VAR class="block-variable" data-id="${this.value}"></VAR>
		`);
		elm.append(text);

		range.insertNode(elm);
		this.api.selection.expandToTag(elm);
	}

	unwrap(range) {
		if (!this.var)
			return;

		this.state = false;
		const text = range.extractContents();
		this.var.remove();

		range.insertNode(text);
	}

	surround(range) {
		if (this.state)
			this.unwrap(range);
		else
			this.wrap(range);
	}

	checkState(selection) {
		this.state = !!this.var;
		this.value = this.var ? this.var.dataset.id : null;
		this.select.value = this.value;
		this.select.style.display = this.state ? "inline" : "none";
		this.button.classList.toggle(this.api.styles.inlineToolButtonActive, this.state);
	}

	get var() {
		return this.api.selection.findParentTag("VAR", "block-variable");
	}

	static get sanitize() {
		return {
			var : true
		};
	}

	static get isInline() {
		return true;
	}
}

export default VariableBlock;
