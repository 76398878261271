import {useState, useCallback} from "react";
/**
 * `useForm()` hook
 * 
 * this should simplify/streamline the handling of forms all over the project
 * 
 * USAGE:
 *     const [formData, handleInput, clear] = useForm({
 *         name       : "",
 *         email      : "",
 *         registered : false
 *     });
 *     
 *     const save = event => {
 *         console.log("SAVED!");
 *         clear();
 *     }
 *     
 *     return (
 *         <>
 *             <input type="text" name="name" value={formData.name} onChange={handleInput} />
 *             <button onClick={save} />
 *         </>
 *     );
 * 
 * @author Michael Ochmann <michael.ochmann@propeller.de>
 */
const useForm = (initialState) => {
	const [formData, setFormData] = useState(initialState);

	const handleInput = event => {
		event.persist();
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setFormData(oldData => { return {...oldData, [event.target.name] : value}});
	};

	const clear = useCallback((callback = null) => {
		if (callback && typeof callback === "function")
			setFormData(callback(initialState));
		else
			setFormData(initialState);
	}, [initialState]);

	return [formData, handleInput, clear];
};

export default useForm;