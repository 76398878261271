import React from "react";

import {InfoCircle} from "react-bootstrap-icons";

import Spinner       from "components/shared/Spinner";
import SortableTable from "components/shared/SortableTable";
import PageHeader    from "components/shared/PageHeader";

import useUSStore from "../hooks/useUSStore";

const Headers = [
	{
		label  : "Bezeichnung",
		member : "name",
		width  : "20%"
	},
	{
		label : "Beschreibung",
		member: "description"
	}
];

const RolesList = () => {
	const [roles, rolesReady] = useUSStore(store => [store.roles, store.rolesReady]);

	return (
		<>
			<PageHeader title="Rollen" />
			<article className="alert alert-warning">
				<InfoCircle className="big" style={{verticalAlign : "middle"}} />
				Benutzerrollen werden über das ProAPI Backend administriert.
			</article>
			{rolesReady ? <SortableTable headers={Headers} data={roles} /> : <Spinner /> }
		</>
	);
};

export default RolesList;
