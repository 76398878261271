import React, {useEffect, useState, useMemo} from "react";
import {NavLink} from "react-router-dom";

import {
	Pencil,
	PersonPlusFill,
	Trash,
	Briefcase,
	CheckSquare,
	XSquare,
	CalendarEvent,
} from "react-bootstrap-icons";
import {useAlert} from "react-alert";

import Spinner         from "components/shared/Spinner";
import SortableTable   from "components/shared/SortableTable";
import YesNoModal      from "components/shared/YesNoModal";
import Select          from "components/shared/Select";
import useLocalStorage from "components/hooks/useLocalStorage";
import Toggle          from "components/shared/Toggle";
import Std             from "utils/Std";

import usePCStore            from "../hooks/usePCStore";
import SearchField           from "./forms/SearchField";
import StudentsEdit          from "./modals/StudentsEdit";
import CellStudentStatistics from "./CellStudentStatistics";

const StudentsList = () => {
	const alert                           = useAlert();
	const ready                           = usePCStore(store => store.studentsReady);
	const students                        = usePCStore(store => store.students);
	const filtered                        = usePCStore(store => store.filtered);
	const courses                         = usePCStore(store => store.courses);
	const [showEdit, setShowEdit]         = usePCStore(store => [store.editShow, store.editShowSet]);
	const [showDelete, setShowDelete]     = useState(false);
	const [search, setSearch]             = usePCStore(store => [store.search, store.searchSet]);
	const [course, setCourse]             = usePCStore(store => [store.course, store.courseSet]);
	const [showInactive, setShowInactive] = useLocalStorage("marvolo-PC-showInactiveStudents", false);
	const [student, setStudent]           = usePCStore(store => [store.student, store.studentSet]);

	const filterStudents = usePCStore(store => store.studentsFilter);
	const studentDelete  = usePCStore(store => store.studentsDelete);

	const deleteStudent = () => studentDelete(alert);

	// FILTERS
	useEffect(() => {
		filterStudents(showInactive);
	}, [search, students, course, showInactive, filterStudents]);


	const headers = useMemo(() => [
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "50px",
			align      : "center",
			functional : ({member}) => {
				return (
					<span title={member.active ? "aktiv" : "inaktiv"}>
						{member.active ?
							<CheckSquare style={{opacity : 1}} className="text-success" />
						: <XSquare style={{opacity : 1}} className="text-danger" />
						}
					</span>
				)
			}
		},
		{
			label  : "Name",
			member : "lastname",
			width  : "15%"
		},
		{
			label  : "Vorname",
			member : "firstname"
		},
		{
			label      : "Statistik",
			member     : null,
			sortable   : false,
			functional : ({member}) => {
				return <CellStudentStatistics student={member} />
			}
		},
		{
			label: "Kurs",
			member: "course.name"
		},
		{
			label : "Jahr",
			member: "course.year"
		},
		{
			label  : "",
			member : null,
			sortable : false,
			width: "200px",
			align : "right",
			functional : ({member}) => {
				return (
					<>
						<NavLink to={`/PracticeChief/students/${member.id}/hours`}>
							<button className="btn" title="Stundeneinträge"><CalendarEvent /></button>
						</NavLink>
						<NavLink to={`/PracticeChief/students/${member.id}/deployments`}>
							<button className="btn" title="Einsätze"><Briefcase /></button>
						</NavLink>
						<button className="btn" onClick={() => {setStudent(member); setShowEdit(true);}} title="Bearbeiten"><Pencil /></button>
						<button className="btn" onClick={() => {
							setStudent(member);
							setShowDelete(true);
						}} title="Löschen">
							<Trash />
						</button>
					</>
				)
			}
		}
	], [setShowEdit, setStudent]);

	return (
		<>
			<h2 className="py-4">Auszubildende<button className="btn btn-success btn-sm float-right" onClick={() => {setStudent(null); setShowEdit(true); }}><PersonPlusFill className="big" /> Auszubildende/n anlegen</button></h2>
			<section className="filter grid three-thirds">
				<SearchField search={search} setSearch={setSearch} />
				<p className="grid ten-ninety" style={{padding : 0, margin : 0}}>
					<label style={{justifySelf : "right", alignSelf : "center", margin: 0}}>Kurs</label>
					<Select data={Std.SortAoOByMember(courses, "name")} value={course} defaultText="alle" onChange={event => setCourse(event.target.value)} firstSelectable={true} />
				</p>
				<div className="grid ten-ninety" style={{padding : 0, margin : 0}}>
					<Toggle name="active" checked={showInactive} onChange={event => setShowInactive(event.target.checked)} style={{alignSelf : "center", justifySelf : "right"}} />
					<label style={{alignSelf : "center", justifySelf : "left", margin : 0}}>zeige auch inaktive Auszubildende</label>
				</div>
			</section>
			{ready ?
				<SortableTable headers={headers} data={filtered} sorting="lastname" />
				: <Spinner />
			}
			<StudentsEdit show={showEdit} setShow={setShowEdit} currentStudent={student} courses={courses} />
			<YesNoModal show={showDelete} setShow={setShowDelete} text={
				`Möchten Sie den Schüler '${student ? student.firstname + " " + student.lastname : ""}' wirklich löschen?`
			} callback={deleteStudent} />
		</>
	);
};

export default StudentsList;
