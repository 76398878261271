import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {Calendar3} from "react-bootstrap-icons";

const DateInput = ({value, onChange = () => {}, className, name, placeholder}) => {

	return (
		<>
			<div className="input-group">
				<div className="input-group-prepend">
					<div className="input-group-text"><Calendar3 /></div>
				</div>
				<DatePicker
					selected={value}
					dateFormat="dd.MM.yyyy"
					className={`form-control ${className}`}
					placeholderText={placeholder}
					onChange={value =>	onChange({
							target : {
								name,
								value : new Date(value)
							}
						})
					} />
			</div>
		</>
	);
};

export default DateInput;
