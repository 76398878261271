import React from "react";

import {PersonFill} from "react-bootstrap-icons";

const NoStudent = () => {
	return (
		<section className="alert alert-danger grid ten-ninety small-gap" style={{alignItems : "center"}}>
			<PersonFill className="big" />
			Zur Zeit ist kein Auszubildender/keine Auszubildende ausgewählt.
		</section>
	)
}

export default NoStudent;
