import React, {useState, useEffect, useMemo} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import MinutesFormatter    from "../../../../formatters/MinutesFormatter";
import Spinner             from "../../../../components/shared/Spinner";
import SortableTable       from "../../../../components/shared/SortableTable";
import TableFooterTotalSum from "../../../../components/shared/TableFooterTotalSum";

const StatsWards = () => {
	const headers               = [
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label  : "Name",
			member : "name",
			width  : "10%"
		},
		{
			label     : "Stunden gesamt",
			member    : "minutesTotal",
			formatter : MinutesFormatter
		}
	];
	const alert                 = useAlert();
	const [courses, setCourses] = useState([]);
	const [ready, setReady]     = useState(false);

	useEffect(() => {
		axios.get("/wards").then(response => {
			for (const course of response.data) {
				axios.get(`/hour-entries?ward=${course.id}`, {timeout : 10000}).then(response => {
					course.minutesTotal = response.data.reduce((accumulator, item) => accumulator + item.duration_minutes, 0);
					setCourses(courses => { return [...courses, course]});
				});
			}
			setReady(true);
		}).catch(error => alert.error(`Fehler beim laden der Einsatzortdaten: ${error}`));
	}, [alert]);

	const totalMinutes = useMemo(() => {
		return courses.reduce((accumulator, item) => accumulator + item.minutesTotal, 0);
	}, [courses]);

	if (!ready)
		return (
			<>
				<h2 className="py-4">Statistiken "Einsatzorte"</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<h2 className="py-4">Statistiken "Einsatzorte"</h2>
			<SortableTable data={courses} headers={headers} footer={<TableFooterTotalSum colspan={3} content={`Summe: ${MinutesFormatter(totalMinutes)}`} />} />
		</>
	);
};

export default StatsWards;
