import * as Sentry from "@sentry/react";

const Log = (subject, title = "Log event", level = "error", section = "API") => {
	Sentry.withScope(scope => {
		scope.setLevel(level);
		if (typeof  subject === "string") {
			Sentry.captureMessage(subject);
			return;
		}
		subject.name = title;
		Sentry.captureException(subject, {
			tags : {
				section
			}
		});
	});
};

export default Log;
