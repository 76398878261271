import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";

import {useAlert} from "react-alert";
import {Modal}    from "react-bootstrap";

const CategoryModal = ({show, setShow, category, onChange}) => {
	const alert           = useAlert();
	const [name, setName] = useState("");

	useEffect(() => {
		if (category)
			setName(category.name);
		else
			setName("");
	}, [category]);

	const save = useCallback(() => {
		if (!name)
			return;
		if (category) {
			axios.put(`/categories/${category.id}`, {name}).then(() => {
				onChange();
				setShow(false);
			}).catch(error => alert.error(`Fehler beim Speichern der Kategorie: ${error}`));
		} else {
			axios.post("/categories", {name}).then(() => {
				onChange();
				setShow(false);
			}).catch(error => alert.error(`Fehler beim Anlegen der Kategorie: ${error}`))
		}
	}, [alert, category, onChange, name, setShow]);

	return (
		<Modal show={show} size="lg">
			<Modal.Header>
				<Modal.Title>{category ? "Kategorie bearbeiten" : "Kategorie anlegen"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="row">
					<section className="col-md-3">Bezeichnung</section>
					<section className="col-md-9">
						<input className="form-control" onChange={event => setName(event.target.value)} type="text" name="title" value={name} required />
					</section>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
				<button className="btn btn-primary" onClick={save}>{category ? "Speichern" : "Anlegen"}</button>
			</Modal.Footer>
		</Modal>
	)
}

export default CategoryModal;
