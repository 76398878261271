import Std from "../Std";

import paragraph   from "@editorjs/paragraph";

class ParagraphBlock extends paragraph {
	constructor(args) {
		super(args);
		const {data}    = args;
		this.fontSize   = data?.fontSize ? data.fontSize : 10;
		this.spacing    = data?.spacing  ? data.spacing  : 0;
		this.block      = null;
		this.inputFont  = null;
		this.inputSpace = null;
	}

	render() {
		this.block = super.render();
		this.block.style.fontSize = `${this.fontSize}pt`;
		this.block.style.marginTop = `${this.spacing}mm`;

		return this.block;
	}

	renderSettings() {
		this.inputFont = Std.CreateElement(`
				<input class="form-control" type="number" step="1" value="${this.fontSize}" min="6" max="32" />
		`);
		this.inputSpace = Std.CreateElement(`
				<input class="form-control" type="number" step="5" value="${this.spacing}" min="-50" max="50" />
		`);

		this.inputFont.addEventListener("change", event => {
			this.fontSize = event.target.value;
			this.block.style.fontSize = `${this.fontSize}pt`;
		});
		this.inputSpace.addEventListener("change", event => {
			this.spacing = event.target.value;
			this.block.style.marginTop = `${this.spacing}mm`;
		});

		const ui = Std.CreateElement(`
			<article class="block-columns-settings">
				<label for="colCount" class="form-label">Schriftgröße</label>
			</article>
		`);

		ui.append(this.inputFont);
		ui.append(Std.CreateElement(`<span><br /><label for="colCount" class="form-label">Abstand davor</label></span>`));
		ui.append(this.inputSpace);

		return ui;
	}

	save() {
		return {
			...this.data,
			fontSize : this.fontSize,
			spacing  : this.spacing
		}
	}
}

export default ParagraphBlock;
