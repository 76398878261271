import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, NavLink} from "react-router-dom";

import "./_styles.scss";

import {Files} from "react-bootstrap-icons";

import UserContext from "../../components/UserContext";

import TemplateList from "./components/TemplateList";
import EditTemplate from "./components/EditTemplate";

const PDFTemplates = ({type}) => {
	const {permissions}     = useContext(UserContext);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (typeof permissions["users-permissions"] !== "undefined")
			setReady(true);
	}, [permissions])

	if (!ready || permissions.application.controllers.templates?.find.enabled !== true)
		return <></>

	if (type === "nav")
		return (
			<NavLink className={({isActive}) => isActive ? "active" : ""} to="/PDFTemplates"><Files /> <span>Vorlagen</span></NavLink>
		);

	return (
		<>
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/PDFTemplates">Übersicht</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="/PDFTemplates">Zuordnungen</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route index element={<TemplateList />} />
				<Route exact path=":id" element={<EditTemplate />} />
			</Routes>
		</>
	)
};

export default PDFTemplates;
