import React from "react";

import {WifiOff} from "react-bootstrap-icons";

const Offline = ({logout}) => {
	return (
		<section className="offline">
			<section className="content">
				<p>
					<WifiOff />
				</p>
				<h2>Netzwerkproblem</h2>
				<section className="description my-5">
					Die Verbindung zum marvolo. Server scheint gestört zu sein.<br />
					Versuchen Sie bitte die Seite neu zu laden, oder melden Sie sich neu an.
					<p className="my-5">
						<button onClick={logout} className="btn btn-primary">jetzt neu anmelden</button>
					</p>
				</section>
			</section>
		</section>
	);
};

export default Offline;