import React from "react";

const Select = ({data, onChange, defaultText, member, current = "", firstSelectable = false,  ...args}) => {
	return (
		<select className="form-control" value={current} onChange={event => {
			event.persist();
			onChange(event);
		}} {...args}>
			<option value="" disabled={!firstSelectable}>{defaultText ? defaultText : "Auswählen..."}</option>
			{
				data.map(item => {
					return <option key={item.id} value={item.id}>{member ? item[member] : item.name}</option>
				})
			}
		</select>
	);
}

export default Select;
