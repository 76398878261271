import React, {useState, useEffect, useRef} from "react";
import axios from "axios";

import {useAlert}  from "react-alert";
import Papa        from "papaparse";
import {FileDrop}  from "react-file-drop";
import {FileEarmarkArrowDown} from "react-bootstrap-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import Std     from "../../../utils/Std";
import Spinner from "../../../components/shared/Spinner";
import Select  from "../../../components/shared/Select";

import ImportModal from "../modals/ImportModal";

const Import = () => {
	const fileInput           = useRef();
	const alert               = useAlert();
	const [ready, setReady]   = useState(false);
	const [tables, setTables] = useState([]);
	const [table, setTable]   = useState("");
	const [data, setData]     = useState([]);
	const [show, setShow]     = useState(false);
	const [model, setModel]   = useState(null);

	useEffect(() => {
		axios.get("/apireflection/").then(response => {
			setTables(response.data.map(item => { return {id : item, label : item}}));
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Tabelleninformationen: ${error}`));
	}, [alert]);

	useEffect(() => {
		if (!table || table === "")
			return;
		axios.get(`/apireflection/${table}`).then(response => {
			setModel(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Tabellenbeschreibung: ${error}`));
	}, [table, alert]);

	const parse = files => {
		if (files.length < 1)
			return;
		Papa.parse(files[0], {
			skipEmptyLines : true,
			complete       : result => {
				setData(result.data);
				setShow(true);
				fileInput.current.value = null;
			}
		});
	}

	const loadFile = (files, event) => {
		if (files.length !== 1)
			return;
		parse(files);
	}

	const handleFileInput = event => {
		const {files} = event.target;
		parse(files);
	};

	if (!ready)
		return (
			<Spinner />
		)

	return (
		<>
			<h2 className="py-4">Importer</h2>
			<p>
				<b>
					Wählen Sie den Inhaltstyp, in den Daten importiert werden sollen:
				</b>
			</p>
			<section className="grid three-thirds">
				<Select current={table} data={tables} defaultText="Importieren in Tabelle..." member="label" onChange={event => setTable(event.target.value)} />
			</section>
			{ table !== "" ?
				<>
					<h5>Aufbau des Inhalttyps</h5>
					<section className="excel-table">
						<table>
							<thead>
							<tr>
								{model ? Std.Range(Object.keys(model.attributes).length).map((_, index) => <th key={index}>{Std.IntToAlphabet(index)}</th>) : <td></td>}
							</tr>
							</thead>
							<tbody>
								<tr className="headline">
									{model ? Object.keys(model.attributes).map((item, index) => {
										const attr = model.attributes[item];
										return (
											<td key={index}>
												<OverlayTrigger key={index} placement="bottom" overlay={
													<Tooltip id={`tooltip-${index}`}>
														<span>Typ: {attr.hasOwnProperty("type") ? <b>{attr.type}</b> : [<b key="a">Relation</b>,<i key="b"> (zu <code key="c">{attr.model}</code></i>, ")"]}</span><br />
														Erforderlich: {attr.hasOwnProperty("required") && attr.required ? <b style={{color:"red"}}>Ja</b> : <b style={{color:"lightgreen"}}>Nein</b>}
													</Tooltip>
												}>
													<span style={{cursor : "pointer"}}>{item}</span>
												</OverlayTrigger>
											</td>
										)
									}) : <td></td>}
								</tr>
							</tbody>
						</table>
					</section>
					<section className="dropzone text-primary">
						<FileDrop onDrop={loadFile} onTargetClick={() => fileInput.current.click()} >
							<FileEarmarkArrowDown style={{ fontSize : "8rem" }} />
							<p className="my-5">
								CSV zum Importieren hier ablegen
							</p>
						</FileDrop>
					</section>
				</>
				: ""
			}
			<ImportModal show={show} setShow={setShow} data={data} model={model} table={table} />
			<input type="file" ref={fileInput} onChange={handleFileInput} className="manual-file-input" accept=".csv,text/csv" />
		</>
	)
};

export default Import;
