import React, {useState, useRef, useEffect, useCallback}  from "react";
import axios from "axios";

import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import * as Sentry                                        from "@sentry/react"
import L                                                  from "leaflet";

import Log from "../utils/Log";

import LoginForm      from "./LoginForm";
import Sidebar        from "./Sidebar";
import ForgotPassword from "./ForgotPassword";
import ResetPassword  from "./ResetPassword";
import BugTracker     from "./BugTracker";

import Modules         from "./../modules/**/*";
import UserContext     from "./UserContext";
import useLocalStorage from "./hooks/useLocalStorage";
import Offline         from "./Offline";

console.log("loaded modules:", Modules);

/*
 * reset for leaflet icons in order to work with `react-leaflet`
 */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl : require("leaflet/dist/images/marker-icon-2x.png").default,
	iconUrl       : require("leaflet/dist/images/marker-icon.png").default,
	shadowUrl     : require("leaflet/dist/images/marker-shadow.png").default
});

const Marvolo = ({cSettings}) => {
	const [identifier, setUsername]           = useState("");
	const [password, setPassword]             = useState("");
	const loginForm                           = useRef();
	const [token, setToken]                   = useLocalStorage("proapi-token");
	const [authUser, setAuthUser]             = useLocalStorage("proapi-authUser");
	const [permissions, setPermissions]       = useState({});
	const [clientSettings, setClientSettings] = cSettings;
	const [offline, setOffline]               = useState(false);

	axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
	axios.defaults.timeout = 5000;
	axios.interceptors.response.use(response => {
		setOffline(false);

		return response;
	}, error => {
		if (!error.response || (error.response && error.response.status === 401))
			setOffline(true);

		return Promise.reject(error);
	});

	const getPermissions = useCallback(roleID => {
		axios.get(`/users-permissions/roles/${roleID}`).then(response => {
			setPermissions(response.data.role.permissions);
		}).catch(error=>{});
	}, [setPermissions]);

	useEffect(() => {
		if (token !== "" && token !== null && token !== "null") {
			axios.defaults.headers.common = {
				Authorization : `Bearer ${token}`
			};
		}
	}, [token]);

	useEffect(() => {
		if (authUser !== null && typeof authUser.role !== "undefined")
			getPermissions(authUser.role.id);
	}, [authUser, getPermissions]);

	/**
	 * Detects, if application is offline. Continueing using the application
	 * when offline makes no sense, so we block the whole user interface using
	 * the <Offline /> component
	 * 
	 * @author Michael Ochmann <michael.ochmann@propeller.de>
	 * @since 0.0.5
	 */
	useEffect(() => {
		const offlineListener = window.addEventListener("offline", () => setOffline(true));
		const onlineListener  = window.addEventListener("online", () => setOffline(false));

		return () => {
			window.removeEventListener("offline", offlineListener);
			window.removeEventListener("online", onlineListener);
		}
	}, []);

	const handleLogin = useCallback(event => {
		event.preventDefault();
		axios.post("/auth/local", {
			identifier,
			password
		}).then(result => {
			setToken(result.data.jwt);
			setAuthUser(result.data.user);
			setPassword("");
			Sentry.setUser({
				username : result.data.user.username,
				email    : result.data.user.email,
				id       : result.data.user.id
			});
		}).catch(error => {
			Log(error, "Failed login", "info");
			loginForm.current.classList.remove("wrong");
			void loginForm.current.offsetWidth;
			loginForm.current.classList.add("wrong");
		});
	}, [identifier, password, loginForm, setToken, setAuthUser, setPassword]);

	const handleLogout = useCallback(() => {
		setToken(null);
		setAuthUser(null);
		delete axios.defaults.headers.common;
	}, [setToken, setAuthUser]);

	const changeClientSettings = (key, value) => {
		let oldSettings = {...clientSettings};
		oldSettings[key] = value;
		setClientSettings(oldSettings);
	};

	const defaultRoute = process.env.REACT_APP_DEFAULT_ROUTE && process.env.REACT_APP_DEFAULT_ROUTE !== "" ?
		process.env.REACT_APP_DEFAULT_ROUTE : null;

	if (authUser) {
		return (
			<Router>
				<UserContext.Provider value={{authUser, permissions}}>
						<div className="wrapper">
						<Sidebar modules={Modules} authUser={authUser} logout={handleLogout} permissions={permissions} cSettings={cSettings} changeClientSettings={changeClientSettings} />
						<main className="content">
							<div>
								<div className="bg-white p-4">
									<Routes>
										{Object.entries(Modules).map(module => {
											const [name, Component]    = module;
											return (
												<Route path={`/${name}/*`} key={name} element={<Component clientSettings={clientSettings} />} />
											);
										})}
										{defaultRoute ?
											<Route exact path="/*" element={<Navigate to={`/${defaultRoute}`} />} />
											: ""
										}
									</Routes>
								</div>
							</div>
							<BugTracker modules={Modules} />
						</main>
					</div>
					{offline ? 
						<Offline logout={handleLogout} />
					: null }
				</UserContext.Provider>
			</Router>
		);
	} else {
		return (
		<Router>
			<Routes>
				<Route exact path="/forgot-password" element={<ForgotPassword darkMode={clientSettings.darkTheme} />} />
				<Route exact path="/reset-password/:code" element={<ResetPassword darkMode={clientSettings.darkTheme} />} />
				<Route path="*" element={<LoginForm login={handleLogin} darkMode={clientSettings.darkTheme} setPassword={setPassword} setUsername={setUsername} reference={loginForm} />} />
			</Routes>
			<section className="copyright">
				&copy; 2019 – 2022, propeller GmbH
			</section>
		</Router>

		);
	}
}

export default Marvolo;
