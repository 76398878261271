class ClientSettings {
	constructor() {
		this.darkTheme      = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
		this.sidebarVisible = true;
	}
}
ClientSettings.Listeners = (callback) => {
	window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", event => {
		callback(event.matches);
	});
};

export default ClientSettings;
