import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {PersonSquare, X} from "react-bootstrap-icons";
import {useAlert}     from "react-alert";
 
import Std                 from "utils/Std";
import MinutesFormatter    from "../../../../formatters/MinutesFormatter";
import Spinner             from "../../../../components/shared/Spinner";
import SortableTable       from "../../../../components/shared/SortableTable";
import TableFooterTotalSum from "../../../../components/shared/TableFooterTotalSum";
import DateInput           from "../../../../components/shared/DateInput";
import { StrapiDate }      from "utils/StrapiUtils";
import Time				   from "utils/Time";

const headers               = [
	{
		label  : "Id",
		member : "id",
		width  : "90px"
	},
	{
		label  : "Name",
		member : "name",
		width  : "25%"
	},
	{
		label     : "Stunden gesamt",
		member    : "minutes_total",
		formatter : MinutesFormatter,
		align     : "right"
	}
];

const StatsCourses = () => {
	const alert                                 = useAlert();
	const [course, setCourse]                   = useState(null);
	const [courses, setCourses]                 = useState([]);
	const [filteredCourses, setFilteredCourses] = useState([]);
	const [ready, setReady]                     = useState(false);
	const [from, setFrom]                       = useState(null);
	const [to, setTo]                           = useState(null);

	const load = useCallback(() => {
		setReady(false);
		const range = [];

		if (from)
			range.push(`from=${new StrapiDate(from)}`);
		if (to)
			range.push(`to=${new StrapiDate(to)}`);
		const rangeString = range.length > 0 ? `?${range.join("&")}` : "";

		axios.get(`/courses/statistics${rangeString}`,{ timeout : 2 * Time.MINUTES}).then(response => {
			setCourses(response.data);
			setFilteredCourses(response.data);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Statistiken: ${error}`));
	}, [alert, from, to]);

	useEffect(() => {
		load();
	}, [load, from, to]);

	useEffect(() => {
		if (!course) {
			setFilteredCourses([...courses]);

			return;
		}
		setFilteredCourses(courses.filter(item => item.id === course));
	}, [course, courses]);

	const totalMinutes = useMemo(() => {
		return filteredCourses.reduce((accumulator, item) => accumulator + item.minutes_total, 0);
	}, [filteredCourses]);

	return (
		<>
			<h2 className="py-4">Statistiken "Kurse"</h2>
			<section className="grid three-thirds">
				<div>
					<div className="input-group">
						<div className="input-group-prepend">
							<div className="input-group-text" title="Einsatz auswählen"><PersonSquare /></div>
						</div>
						<select name="course" style={{fontWeight : "normal", fontSize : "1rem"}} value={course || ""} className="form-control" onChange={event => setCourse(event.target.value)}>
							<option value="">alle</option>
							{Std.SortAoOByMember(courses, "name").map((course, index) => {
								return <option value={course.id} key={index}>{course.name}</option>
								})
							}
						</select>
					</div>
				</div>
				<div className="date-input">
					<DateInput placeholder="Von" value={from || ""} onChange={event => setFrom(event.target.value === "" ? null : event.target.value)} />
					{
						from ?
							<button type="button" className="btn bg-transparent del" style={{marginLeft: "-40px", zIndex: 100}} onClick={() => setFrom(null)}>
								<X />
							</button> : null
					}
				</div>
				<div className="date-input">
					<DateInput placeholder="Bis" value={to || ""} onChange={event => setTo(event.target.value === "" ? null : event.target.value)} />
					{
						to ?
							<button type="button" className="btn bg-transparent del" style={{marginLeft: "-40px", zIndex: 100}} onClick={() => setTo(null)}>
								<X />
							</button> : null
					}
				</div>
			</section>
			{ ready ?
				<SortableTable data={filteredCourses} headers={headers} footer={<TableFooterTotalSum colspan={3} content={`Summe: ${MinutesFormatter(totalMinutes)}`} />} />
				: <Spinner />
			}
		</>
	);
};

export default StatsCourses;
