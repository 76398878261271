import React, {useCallback, useEffect} from "react";

import {useAlert} from "react-alert";

import useForm from "components/hooks/useForm";
import Select  from "components/shared/Select";
import Modal   from "components/shared/Modal";

import useUSStore from "../hooks/useUSStore";

const EditScreen = ({currentUser, show, setShow, roles}) => {
	const alert                          = useAlert();
	const [formData, handleInput, clear] = useForm({
		username  : "",
		email     : "",
		firstname : "",
		lastname  : "",
		role      : ""
	});

	const saveUser = useUSStore(store => store.userSave);

	useEffect(() => {
		if (!currentUser)
			clear();
		else
			clear(() => ({...currentUser, role : currentUser.role?.id}));
	}, [currentUser]);

	const save = useCallback(() => {
		saveUser(formData, () => {
			setShow(false);
		}, alert);
	}, [formData, alert, setShow, saveUser]);

	return (
		<Modal size="lg" scrollable={true} show={show} onHide={() => setShow(false)}>
			<Modal.Header>
				<Modal.Title>
					{currentUser === null ? "Benutzer anlegen" : "Benutzer bearbeiten"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="grid form">
					<label>Benutzername</label>
					<input className="form-control" onChange={handleInput} minLength={3} type="text" name="username" value={formData.username} required />
					
					<label>Vorname</label>
					<input className="form-control" onChange={handleInput} type="text" name="firstname" value={formData.firstname} required />
					
					<label>Nachname</label>
					<input className="form-control" onChange={handleInput} type="text" name="lastname" value={formData.lastname} required />
					
					<label>E-Mail</label>
					<input className="form-control" type="text" name="email" minLength={6} onChange={handleInput} value={formData.email} required />
					
					<label>Benutzerrolle</label>
					<Select data={roles} defaultText="Benutzerrolle auswählen" current={formData.role} onChange={handleInput} name="role" required />
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
				<button className="btn btn-success" onClick={save}>Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditScreen;
