import React, {useCallback, useEffect, useState, useMemo} from "react";
import axios from "axios";

import {useAlert} from "react-alert";
import { escapeRegExp } from "lodash";

import Spinner       from "../../../components/shared/Spinner";
import SortableTable from "../../../components/shared/SortableTable";
import YesNoModal    from "../../../components/shared/YesNoModal";
import Time          from "utils/Time";

import {FileEarmarkPlus, Trash, Pencil, CheckSquare, XSquare} from "react-bootstrap-icons"

import CourseEdit  from "./modals/CourseEdit";
import SearchField from "./forms/SearchField";


const CourseList = () => {
	
	const alert                       = useAlert();
	const [ready, setReady]           = useState(false);
	const [courses, setCourses]       = useState([]);
	const [filtered, setFiltered]     = useState([]);
	const [showEdit, setShowEdit]     = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [search, setSearch]         = useState("");
	
	const [course, setCourse]         = useState(null);
	const headers = useMemo(()=>[
		{
			label : "ID",
			member : "id",
			width: "8%"
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "50px",
			align      : "center",
			functional : ({member}) => {
				return (
					<span title={member.active ? "aktiv" : "inaktiv"}>
						{member.active ?
							<CheckSquare style={{opacity : 1}} className="text-success" />
						: <XSquare style={{opacity : 1}} className="text-danger" />
						}
					</span>
				)
			}
		},
		{
			label : "Bezeichnung",
			member: "name"
		},
		{
			label: "Jahr",
			member: "year"
		},
		{
			label: "",
			member: null,
			sortable: false,
			width : "8%",
			align : "right",
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {
							setCourse(member);
							setShowEdit(true);
						}}><Pencil />
						</button>
						<button className="btn" onClick={() => {
							setCourse(member);
							setShowDelete(true);
						}}>
							<Trash />
						</button>
					</>
				);
			}
		}
	], []);
	
	const loadCourses = useCallback(() => {
		axios.get("/courses", { timeout : 2 * Time.MINUTES}).then(response => {
			setCourses(response.data);
			setReady(true);
		}).catch(error => {
			alert.error(`Beim laden der Kurse ist ein Fehler aufgetreten: ${error}`);
		});
	}, [alert]);

	const deleteCourse = useCallback(() => {
		axios.delete(`/courses/${course.id}`).then(response => {
			alert.success(`Der Kurs '${course.name}' [${course.year}] wurde gelöscht`);
			setCourse(null);
			loadCourses();
		}).catch(error => alert.error(`Fehler beim löschen des Kurses: ${error}`));
	}, [course, loadCourses, alert]);

	useEffect(() => {
		loadCourses();
	}, [loadCourses]);

	useEffect(() => {
		setFiltered(search === "" ? courses : courses.filter(course => course.name.match(new RegExp(`${escapeRegExp(search)}`, "i"))));
	}, [courses, search]);

	return (
		<>
			<h2 className="py-4">
				Kursübersicht
				<button className="btn btn-success btn-sm float-right"
					onClick={event => {setCourse(null); setShowEdit(true);}}>
					<FileEarmarkPlus className="big" />Kurs anlegen
				</button>
			</h2>
			<section className="filter grid three-thirds">
				<SearchField search={search} setSearch={setSearch} />
			</section>
			
			{ready ?
				<SortableTable data={filtered} headers={headers} sorting="name" />
				: <Spinner />
			}
			<CourseEdit onSave={loadCourses} currentCourse={course} show={showEdit} setShow={setShowEdit} />
			<YesNoModal text={
				`Möchten Sie den Kurs '${course ? course.name : ""}' [${course ? course.year : ""}] wirklich löschen?`
			} show={showDelete} setShow={setShowDelete} callback={deleteCourse} />
		</>
	);
};

export default CourseList;
