import Std from "../Std";

import {Table} from "../StringIcons";

class TabularDataPlaceholderBlock {
	render() {
		return Std.CreateElement(`
			<table class="block-tabular">
				<tr>
					<th>Spalte A</th>
					<th>Spalte B</th>
					<th>Spalte C</th>
				</tr>
				<tr>
					<td>Dies ist</td>
					<td>eine</td>
					<td>Beispieltabelle</td>
				</tr>
				<tr>
					<td colspan="3">Dieser Inhalt wird beim Export durch dynamische Daten ersetzt. Sind keine tabellarischen Daten verfügbar, sehen Sie eine Beispielausgabe.</td>
				</tr>
				<tr>
					<td>Zeile 3 - A</td>
					<td>Zeile 3 - B</td>
					<td>Zeile 3 - C</td>
				</tr>
			</table>
		`);
	}

	save() {
		return {};
	}

	static get toolbox() {
		return {
			title : "Tabellarische Daten",
			icon  : Table
		}
	}
}

export default TabularDataPlaceholderBlock;
