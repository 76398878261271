import React, {useEffect, useState, useCallback, useRef} from "react";
import axios from "axios";

import {useAlert}    from "react-alert";
import {Form, Modal} from "react-bootstrap";

import Toggle from "components/shared/Toggle";

const WardEdit = ({ward, show, setShow, callback}) => {
	const alert                   = useAlert();
	const form                    = useRef();
	const [formData, setFormData] = useState({
		name     : "",
		phone    : "",
		email    : "",
		leader   : "",
		external : false
	});

	useEffect(() => {
		if (!ward)
			return;

		setFormData({
			name     : "",
			phone    : "",
			email    : "",
			leader   : "",
			external : false,
			...ward
		});
	}, [ward, show]);

	const handleInput = useCallback(event => {
		const value   = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		const oldData = {...formData};
		oldData[event.target.name] = value;

		setFormData(oldData);
	}, [formData])

	const save = useCallback(() => {
		console.log(formData);
		if (ward) {
			axios.put(`/wards/${ward.id}`, {...formData}).then(() => {
				alert.success(`Der Einsatzort '${ward.name} wurde erfolgreich gespeichert!`);
				callback();
				setShow(false);
			}).catch(error => alert.error(`Fehler beim Speichern des Einsatzortes '${ward.name}': ${error}`));
		} else {
			axios.post("/wards", {...formData}).then(() => {
				alert.success(`Der Einsatzort '${formData.name} wurde erfolgreich angelegt!`);
				callback();
				setShow(false);
			}).catch(error => alert.error(`Fehler beim Anlegen des Einsatzortes '${formData.name}': ${error}`));
}
	}, [alert, ward, callback, setShow, formData]);

	return (
		<form ref={form}>
			<Modal show={show} size="lg">
				<Modal.Header>
					<Modal.Title>Einsatzort {ward ? "bearbeiten" : "anlegen"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid thirty-seventy">
						<label>Bezeichnung</label>
						<input type="text" className="form-control" name="name" value={formData.name} onChange={handleInput} required />
						<label>Telefonnummer</label>
						<input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleInput} />
						<label>E-Mail</label>
						<input type="text" className="form-control" name="email" value={formData.email} onChange={handleInput}  />
						<label>Abteilungsleiter</label>
						<input type="text" className="form-control" name="leader" value={formData.leader} onChange={handleInput} />
						<label>Extern</label>
						<Toggle name="external" onChange={handleInput} checked={formData.external} label="" id="external" />
					</section>
				</Modal.Body>
				<Modal.Footer>
							<span className="btn btn-outline-danger" onClick={() => {
								setShow(false);
							}}>Abbrechen</span>
					<button className="btn btn-success" onClick={() => save()}>{ward ? "Speichern" : "Anlegen"}</button>
				</Modal.Footer>
			</Modal>
		</form>
	);
};

export default WardEdit;
