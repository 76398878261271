import React, {useEffect} from "react";

import ReactDOM                                            from "react-dom";
import {transitions, positions, Provider as AlertProvider} from "react-alert";
import * as Sentry                                         from "@sentry/react";
import {Integrations}                                      from "@sentry/tracing";

import "./assets/css/frontend.scss";
import "./assets/css/frontend.scss";
import "bootstrap/dist/js/bootstrap.js";

import AlertTemplate   from "./components/AlertTemplate";
import Marvolo         from "./components/Marvolo";
import useLocalStorage from "./components/hooks/useLocalStorage";
import ClientSettings  from "./components/ClientSettings";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 2.0,
});

const options = {
    position       : positions.BOTTOM_CENTER,
    timeout        : 6000,
    offset         : "30px",
    transition     : transitions.SCALE,
    containerStyle : {
        textTransform : "initial !important",
    }
}

const Root = () => {
    const [clientSettings, setClientSettings] = useLocalStorage("proapi-settings", new ClientSettings());

    ClientSettings.Listeners(darkTheme => {
        const oldSettings = {...clientSettings};
        oldSettings.darkTheme = darkTheme;
        setClientSettings(oldSettings);
    });

    useEffect(() => {
        if (clientSettings.darkTheme)
            document.body.classList.add("theme-dark");
        else
            document.body.classList.remove("theme-dark");
    }, [clientSettings]);

	return (
        <AlertProvider template={AlertTemplate} {...options}>
            <Marvolo cSettings={[clientSettings, setClientSettings]} />
        </AlertProvider>
    )
}

ReactDOM.render(
    <Root />,
  document.getElementById('root')
);

//serviceWorker.unregister();
