import React from "react";
import {Search, X} from "react-bootstrap-icons";

const SearchField = ({search, setSearch}) => {
	return (
		<>
			<div className="input-group">
				<div className="input-group-prepend">
					<div className="input-group-text"><Search /></div>
				</div>
				<input type="text" className="form-control" placeholder="Suche..." value={search} onChange={event => setSearch(event.target.value)} />
				{
					search.length > 0 ?
						<button type="button" className="btn bg-transparent" style={{marginLeft: "-40px", zIndex: 100}} onClick={() => setSearch("")}>
							<X />
						</button> : null
				}
			</div>
		</>
	);
};

export default SearchField;
