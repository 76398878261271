import React, {useState, useCallback, useContext} from "react";

import {useLocation} from "react-router";
import * as Sentry from "@sentry/react"

import {useAlert}       from "react-alert";
import {QuestionCircle} from "react-bootstrap-icons";

import UserContext from "./UserContext";

const BugTracker = ({modules}) => {
	const userContext             = useContext(UserContext);
	const location                = useLocation();
	const alert                   = useAlert();
	const [showHelp, setShowHelp] = useState(false);
	const [message, setMessage]   = useState("");

	const handleInput = event => {
		setMessage(event.target.value);
	};

	const send = useCallback(() => {
		/*
		const api  = new API(process.env.REACT_APP_SENTRY_URL);
		const info = Sentry.getCurrentHub().getClient().getDsn();
		const url  = api.getReportDialogEndpoint();//`${info.protocol}://${info.host}/api/0/${info.projectId}/user-feedback/`;

		axios.post(url, {
			eventId : Sentry.lastEventId(),
			comments : message,
			email : userContext.authUser.email,
			name : userContext.authUser.username
		}).then(response => console.log(response)).catch(error => console.log(error.response));
*/

		Sentry.withScope(scope => {
			scope.setLevel("info");
			scope.setContext("Bugreport", {
				location,
				modules,
			});
			scope.setUser({
				id       : userContext.authUser.id,
				email    : userContext.authUser.email,
				username : userContext.authUser.username

			});
			scope.setTag("type", "User Bugreport");
			Sentry.captureMessage(message);
			setMessage("");
			setShowHelp(false);
			alert.success("Ihr Fehlerreport wurde übermittelt.")
		});
	}, [message, alert, location, userContext, modules]);

	return (
		<div className={`${showHelp ? "active" : ""} helpscreen`}>
			<section>
				<h3>Ein Problem melden</h3>
				<p className="text-secondary">
					Bitte beschreiben Sie das aufgetretene Problem möglichst genau:
				</p>
				<p>
					<textarea className="form-control" rows={4} value={message} onChange={handleInput}></textarea>
				</p>
				<p className="text-right">
					<button className="btn btn-primary" onClick={() => send()}>Fehlermeldung absenden</button>
				</p>
			</section>
			<span className="open" onClick={() => setShowHelp(!showHelp)}>
				<QuestionCircle className="big text-primary" />
				Problem melden
			</span>
		</div>
	);
};

export default BugTracker;
