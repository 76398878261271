import {StrapiDate} from "utils/StrapiUtils";

const today = new StrapiDate();

class Util {

	static IsActiveDeployment(deployment) {
		const from  = new StrapiDate(deployment.from);
		const to    = new StrapiDate(deployment.to);

		return from <= today && today <= to;
	}

}

export default Util;