import React, {useEffect, useRef, useState, useCallback, useMemo} from "react";
import axios from "axios";

import {useAlert}    from "react-alert";

import Toggle       from "components/shared/Toggle";
//import Modal        from "components/shared/Modal";
import {Modal}      from "react-bootstrap";
import useForm      from "components/hooks/useForm";

import DeploymentInfo  from "../DeploymentInfo";
import DeploymentsList from "../DeploymentsList";
import Util            from "../../Util";
import usePCStore      from "../../hooks/usePCStore";

const InitialState = {
	lastname  : "",
	firstname : "",
	course    : -1,
	active    : false
};

const StudentsEdit = ({currentStudent, show, setShow, courses}) => {
	const alert                          = useAlert();
	const [formData, handleInput, clear] = useForm(InitialState);
	const [hours, setHours]              = useState([]);
	const [deployment, setDeployment]    = usePCStore(store => [store.deployment, store.deploymentSet]);
	const deployments                    = usePCStore(store => store.deployments);
	const deploymentsListComponent       = useRef();

	const updateDeployment = usePCStore(store => store.studentDeployment);
	const studentSave      = usePCStore(store => store.studentSave);

	const currentDeployment = useMemo(() => {
		return deployment !== "" ? deployments[deployment] : null;
	}, [deployment, deployments]);

	useEffect(() => {
		clear(initialState => {
			const course = currentStudent?.course && typeof currentStudent.course === "string" ? currentStudent?.course : currentStudent?.course?.id;
			return !currentStudent ? initialState : {...currentStudent, course};
		});
		updateDeployment(alert);
	}, [currentStudent, clear, updateDeployment, alert]);

	useEffect(() => {
		if (!currentDeployment)
			return;
		axios.get(`/hour-entries?student=${currentStudent.id}&_sort=date:DESC`).then(response => {
			setHours(response.data);
		}).catch(error => error);
	}, [currentStudent, currentDeployment]);

	const saveUser = useCallback(event => {
		event.preventDefault();
	
		studentSave(formData, alert);
	}, [formData, alert, studentSave]);

	return (
		<Modal size="xl" scrollable={true} show={show} onHide={() => setShow(false)}>
				<Modal.Header>
					<Modal.Title>
						{currentStudent === null ? "Auszubildende/n anlegen" : "Auszubildende/n bearbeiten"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={saveUser}>
						<section className="grid thirty-seventy">
							<section>
								<section className="grid form">
									<label>Name</label>
									<input className="form-control" onChange={handleInput} type="text" name="lastname" value={formData.lastname} required />

									<label>Vorname</label>
									<input className="form-control" onChange={handleInput} type="text" name="firstname" value={formData.firstname} required />

									<label>Kurs</label>
									<select className="form-control" name="course" onChange={handleInput} value={formData.course} required>
										<option value={-1} disabled>Kurs auswählen</option>
										{
											courses.map((item, index) => {
												return <option key={index} value={item.id}>{item.name} [{item.year}]</option>
											})
										}
									</select>
									<label>Aktiv</label>
									<Toggle name="active" onChange={handleInput} checked={formData.active} label="" id="active" />
								</section>
							</section>
							<section>
								<DeploymentInfo student={currentStudent} create={!currentStudent} setDeployment={setDeployment} deployments={deployments} deployment={currentDeployment} hours={hours.filter(entry => entry.deployment?.id === currentDeployment?.id)}/>
							</section>
						</section>
					</form>
					<section>
						<DeploymentsList student={currentStudent} deployment={currentDeployment} onChange={() => updateDeployment(alert)} ref={deploymentsListComponent} rowCallback={member => {
							if (Util.IsActiveDeployment(member))
								setDeployment(member.id);
						}} />
					</section>
				</Modal.Body>
				<Modal.Footer>
					<span className="btn btn-outline-danger" onClick={() => {
						setShow(false);
					}}>Abbrechen</span>
					<button className="btn btn-success" onClick={saveUser}>Speichern</button>
				</Modal.Footer>
		</Modal>
	);
};

export default StudentsEdit;
