import React from "react";

import {CheckSquareFill, InfoCircle, ExclamationCircle} from "react-bootstrap-icons";

const AlertTemplate = ({ style, options, message, close }) => {
	return (
		<section className={`p-3 alert-${options.type.replace("error", "danger")}`} style={style}>
			{options.type === "error" && <ExclamationCircle className="big" />}
			{options.type === "success" && <CheckSquareFill className="big" />}
			{options.type === "info" && <InfoCircle className="big" />}
			{message}
			<button type="button" className="close mx-2" onClick={close}>
				<span>&times;</span>
			</button>
		</section>
	);
};

export default AlertTemplate;
