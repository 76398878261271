import React from "react";

const Spinner = ({size, color = "text-primary", ...args}) => {

	const style = {
		width  : size || "5rem",
		height : size || "5rem"
	};

	return (
		<section className="row justify-content-center py-5" {...args}>
			<div className={`spinner-grow ${color} align-self-center`} style={style} role="status">
				<span className="sr-only">Lade...</span>
			</div>
		</section>
	);
}

export default Spinner;
