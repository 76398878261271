import Std from "../Std";

import {Image as ImageIcon, AlignLeft, AlignCenter, AlignRight, Link, XCircleFill, Fullscreen} from "../StringIcons";

class Base64ImageBlock {
	constructor({data}) {
		this.data         = data;
		this.block        = null;
		this.leftButton   = null;
		this.centerButton = null;
		this.rightButton  = null;
		this.image        = new Image();
		this.image.src    = this.data.image;
		this.aspect       = typeof this.data.aspect !== "undefined" ? this.data.aspect : true;

		this.widthInput   = null;
		this.heightInput  = null;
		this.aspectButton = null;
		this.fileInput    = Std.CreateElement(`<input type="file" name="select-image" />`);
		this.fileInput.addEventListener("change", event => {
			this.loadImage(event.target.files[0]);
		})

		this.removeButton = Std.CreateElement(`
			<button class="button-remove">${XCircleFill}</button>
		`);
		this.removeButton.addEventListener("click", () => {
			this.data.image = null;
			this.reload();
		});

		this.form = Std.CreateElement(`
			<section class="file-input">
				<p>
					${Fullscreen}
				</p>
				<p>
					<small>Datei auf Schaltfäche ablegen oder klicken.</small>
				</p>
			</section>
		`);
		this.form.append(this.fileInput);
		this.form.addEventListener("click", () => {
			this.fileInput.click();
		});
		this.form.addEventListener("drop", event => {
			this.loadImage(event.dataTransfer.files[0]);
		});
		this.form.addEventListener("dragover", event => {
			event.stopPropagation();
			event.preventDefault();
			event.dataTransfer.dropEffect = "copy";
		});
		this.form.addEventListener("dragenter", () => this.form.classList.add("drop"));
		this.form.addEventListener("dragleave", () => this.form.classList.remove("drop"));

		this.image.addEventListener("active", () => {
			this.image.style.border = "solid 5px black";
		});

		if (this.data.width && this.data.height) {
			this.image.style.width  = `${this.data.width}mm`;
			this.image.style.height = `${this.data.height}mm`;
		}
	}

	loadImage(file) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			this.data.image  = reader.result;
			this.image.src   = this.data.image;
			this.data.width  = this.image.naturalWidth * 0.1;
			this.data.height = this.image.naturalHeight * 0.1;
			this.reload();
		});
		reader.readAsDataURL(file);
	}

	reload() {
		this.block.innerHTML = "";
		this.image.src       = this.data.image;
		if (!this.data.image || typeof this.data.image !== "string")
			this.block.append(this.form);
		else {
			this.block.append(this.image);
			this.block.append(this.removeButton);
			this.image.style.width  = `${this.data.width}mm`;
			this.image.style.height = `${this.data.height}mm`;
		}
		this.widthInput = Std.CreateElement(`
			<input type="number" value="${this.data.width ? this.data.width : this.image.naturalWidth * 0.1}" name="width" class="form-control" />
		`);

		this.heightInput = Std.CreateElement(`
			<input type="number" value="${this.data.height ? this.data.height : this.image.naturalHeight * 0.1}" name="height" class="form-control" />
		`);
	}

	render() {
		this.block = Std.CreateElement(`
			<section class="block-image" style="text-align: ${this.data.alignment}">
			</section>
		`);
		this.reload();

		return this.block;
	}

	renderSettings() {
		this.leftButton = Std.CreateElement(`
			<article class="ce-settings__button">
				${AlignLeft}
			</article>
		`);
		this.centerButton = Std.CreateElement(`
			<article class="ce-settings__button">
				${AlignCenter}
			</article>
		`);
		this.rightButton = Std.CreateElement(`
			<article class="ce-settings__button" style="margin: 0">
				${AlignRight}
			</article>
		`);

		this.aspectButton = Std.CreateElement(`
			<button class="button-aspect ${this.aspect === true ? "active" : ""}" style="outline: 0;">
				${Link}	
			</button>
		`);

		this.leftButton.addEventListener("click", () => {
			this.data.alignment        = "left";
			this.block.style.textAlign = this.data.alignment;
		});
		this.centerButton.addEventListener("click", () => {
			this.data.alignment        = "center";
			this.block.style.textAlign = this.data.alignment;
		});
		this.rightButton.addEventListener("click", () => {
			this.data.alignment        = "right";
			this.block.style.textAlign = this.data.alignment;
		});

		this.widthInput.addEventListener("change", event => {
			const aspectRatio = this.data.width / this.data.height;

			this.data.width  = event.target.value;
			this.image.style.width = `${this.data.width}mm`;

			if (this.aspect) {
				this.data.height        = this.data.width * aspectRatio;
				this.image.style.height = `${this.data.height}mm`;
				this.heightInput.value  = this.data.height;
			}
		});

		this.heightInput.addEventListener("change", event => {
			const aspectRatio = this.data.width / this.data.height;

			this.data.height  = event.target.value;
			this.image.style.height =`${this.data.height}mm`;

			if (this.aspect) {
				this.data.width        = this.data.height * aspectRatio;
				this.image.style.width = `${this.data.width}mm`;
				this.widthInput.value  = this.data.width;
			}
		});

		this.aspectButton.addEventListener("click", () => {
			if (this.aspect === true) {
				this.aspect = false;
				this.aspectButton.classList.remove("active");
			}
			else {
				this.aspect = true;
				this.aspectButton.classList.add("active");
			}
		});

		const ui     = Std.CreateElement(`<section></section>`);
		const align  = Std.CreateElement(`<p></p>`);
		const width  = Std.CreateElement(`
			<p class="block-image-settings">
				<label>Breite</label>
			</p>
		`);
		const height = Std.CreateElement(`
			<p class="block-image-settings">
				<label>Höhe</label>
			</p>
		`);

		align.append(this.leftButton);
		align.append(this.centerButton);
		align.append(this.rightButton);

		width.append(this.widthInput);
		height.append(this.heightInput);


		ui.append(align);
		ui.append(width);
		ui.append(this.aspectButton);
		ui.append(height);

		return ui;
	}

	save() {
		return {
			...this.data,
			aspect : this.aspect === true
		};
	}

	static get toolbox() {
		return {
			title : "Bild",
			icon  : ImageIcon
		}
	}
}

export default Base64ImageBlock;
