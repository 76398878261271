import React, {useEffect, useState, useCallback} from "react";
import {NavLink} from "react-router-dom";
import axios     from "axios";

import {useAlert}                       from "react-alert";
import {FileEarmarkPlus, Pencil, Trash} from "react-bootstrap-icons";
import {Modal}                          from "react-bootstrap";

import Spinner       from "../../../components/shared/Spinner";
import SortableTable from "../../../components/shared/SortableTable";
import DateFormatter from "../../../formatters/DateFormatter";
import YesNoModal    from "../../../components/shared/YesNoModal";

const TemplateList = (props) => {
	const headers = [
		{
			label  : "Id",
			member : "id",
			width  : "8%"
		},
		{
			label     : "erstellt am",
			member    : "createdAt",
			formatter : DateFormatter
		},
		{
			label  : "Name",
			member : "name"
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "11%",
			functional : ({member}) => {
				return (
					<>
						<button className="btn">
							<NavLink end to={`/PDFTemplates/${member.id}`}>
								<Pencil />
							</NavLink>
						</button>
						<button className="btn" onClick={() => {
							setTemplate(member);
							setShowDelete(true);
						}}>
							<Trash />
						</button>
					</>
				);
			}
		}
	];

	const alert                       = useAlert();
	const [ready, setReady]           = useState(false);
	const [data, setData]             = useState([]);
	const [showDelete, setShowDelete] = useState(false);
	const [showNew, setShowNew]       = useState(false);
	const [template, setTemplate]     = useState(null);
	const [newName, setNewName]       = useState("");

	const loadTemplates = useCallback(() => {
		axios.get("/templates").then(response => {
			setReady(true);
			setData(response.data);
		}).catch(error => alert.error(`Beim Laden der Vorlagen ist ein Fehler aufgetreten: ${error}`));
	}, [alert]);

	useEffect(() => {
		loadTemplates();
	}, [loadTemplates]);

	const deleteTemplate = () => {
		axios.delete(`/templates/${template?.id}`).then(() => {
			alert.success(`Das Template wurde erfolgreich gelöscht.`);
			setTemplate(null);
			loadTemplates();
		}).catch(error => alert.error(`Fehler beim Löschen des Template: ${error}`));
	};

	const create = useCallback(() => {
		axios.post("/templates", {
			name    : newName,
			content : JSON.stringify({
				time   : Date.now(),
				blocks : [
					{
						type : "header",
						data : {
							text      : newName,
							level     : 1,
							alignment : "center"
						}
					}
				],
				version : "2.18.0"
			})
		}).then(() => {
			alert.success(`Template wurde erstellt!`);
			loadTemplates();
		}).catch(error => alert.error(`Fehler beim Anlegen des Templates: ${error}`));
	}, [newName, loadTemplates, alert]);

	if (!ready)
		return (
			<>
				<h2 className="py-4">PDF Vorlagen</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<h2 className="py-4">
				PDF Vorlagen
				<button className="btn btn-success btn-sm float-right" onClick={() => {
					setNewName("");
					setShowNew(true);
				}}> <FileEarmarkPlus className="big" />neues Template</button>
			</h2>
			<SortableTable headers={headers} data={data} />
			<YesNoModal title="Vorlage löschen" show={showDelete} setShow={setShowDelete} text={`Möchten Sie die Vorlage '${template?.name}' wirklich löschen?`} callback={deleteTemplate} />
			<Modal show={showNew}>
				<Modal.Header>
					<Modal.Title>neues Template anlegen</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid thirty-seventy">
						<label>Name</label>
						<input type="text" className="form-control" value={newName} onChange={event => setNewName(event.target.value)} />
					</section>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => {setShowNew(false)}}>Abbrechen</button>
					<button className="btn btn-success" onClick={() => {
						create();
						setShowNew(false);
					}}>Erstellen</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TemplateList;
