import React, {useContext, useEffect, useState} from "react";
import {NavLink, Route, Routes, Navigate} from "react-router-dom";

import {JournalMedical}                   from "react-bootstrap-icons";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {useAlert}                         from "react-alert";
import de from "date-fns/locale/de";

import VariableStore from "utils/VariableStore";
import Spinner       from "components/shared/Spinner";
import UserContext   from "components/UserContext";

import "./_styles.scss";

import HoursSingle     from "./components/routes/HourEntry/HoursSingle";
import StudentsList    from "./components/StudentsList";
import CourseList      from "./components/CourseList";
import Wards           from "./components/administration/Wards";
import Message         from "./components/Message";
import DeploymentsEdit from "./components/DeploymentsEdit";
import HoursEdit       from "./components/HoursEdit";
import StatsCourses    from "./components/statistics/StatsCourses";
import StatsWards      from "./components/statistics/StatsWards";
import CategoriesList  from "./components/CategoriesList";
import usePCStore      from "./hooks/usePCStore";

registerLocale("de", de);
setDefaultLocale("de");

const PracticeChief = ({type}) => {
	const alert                   = useAlert();
	const {authUser, permissions} = useContext(UserContext);
	const [ready, setReady]       = useState(false);
	const loadStudents            = usePCStore(store => store.studentsLoad);
	const loadCourses             = usePCStore(store => store.coursesLoad);

	VariableStore().register("pc-st-firstname", "Schüler - Vorname", "Max")
		.register("pc-st-lastname", "Schüler - Nachname", "Mustermann")
		.register("pc-st-course", "Schüler - Kurs", "Erster Kurs")
		.register("pc-st-course-year", "Schüler – Kurs (Jahr)", "1970")
		.register("pc-st-deployment", "Schüler - aktueller Einsatzort", "Trier")
		.register("pc-st-dfrom", "Schüler - aktueller Einsatz, Beginn", "01. Januar 1970")
		.register("pc-st-dto", "Schüler - aktueller Einsatz, Ende", "28. Februar 1970");

	useEffect(() => {
		if (typeof permissions.application !== "undefined")
			setReady(true);
	}, [permissions]);

	useEffect(() => {
		if (!ready)
			return;

		loadCourses(alert);
		loadStudents(alert);
	}, [alert, ready]);

	if (!ready || !permissions.application.controllers["hour-entry"].create.enabled)
		return <></>

	if (type === "nav") {
		return(
			<>
				<NavLink className={({isActive}) => isActive ? "active" : ""} to="/PracticeChief"><JournalMedical title="Praxisanleiter" /> <span>Praxisanleiter</span></NavLink>
			</>
		);
	} else {
		return (
			<>
				<nav>
					<ul className="nav nav-tabs">
						{permissions.application.controllers.student.create.enabled ?
						<li className="nav-item"><NavLink end className="nav-link" to="students">Auszubildende</NavLink></li>
							: ""
						}
						<li className="nav-item"><NavLink end className="nav-link" to="messages">Nachrichten</NavLink></li>
						{permissions.application.controllers.course.create.enabled ?
							<li className="nav-item"><NavLink end className="nav-link" to="courses">Kurse</NavLink></li>
							: ""
						}
						{permissions.application.controllers.ward.create.enabled ?
							<li className="nav-item"><NavLink end className="nav-link" to="wards">Einsatzorte</NavLink></li>
							: ""
						}
						<li className="nav-item"><NavLink end className="nav-link" to="new">Stunden</NavLink></li>
						{
							authUser.role.type === "admin" ?
								<li className="nav-item"><NavLink end className="nav-link" to="categories">Kategorien</NavLink></li> : ""
						}
						<span className="spacer"/>
						{
							authUser.role.type === "admin" ?
							<>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" data-toggle="dropdown">Statistiken</a>
									<ul className="dropdown-menu">
										<li><NavLink end to="statistics/courses" className="dropdown-item">Kurse</NavLink></li>
										<li><NavLink end to="statistics/wards" className="dropdown-item">Einsatzorte</NavLink></li>
									</ul>
								</li>
								{/*<li className="nav-item dropdown justify-self-end">
								<a href="/" className="nav-link dropdown-toggle" data-toggle="dropdown"
								   role="button">Verwaltung</a>
								<ul className="dropdown-menu">
									<li><a className="dropdown-item" href="/">Nachrichten</a></li>
									<li><NavLink exact className="nav-link" to="wards"> Einsatzorte </NavLink></li>
									<li><a className="dropdown-item" href="/"> Kategorien </a></li>
								</ul>
								</li>*/}
							</>
							: ""
						}
					</ul>
				</nav>
				<Routes>
					<Route end path="new" element={<HoursSingle />} />
					<Route end path="messages" element={<Message />} />
					{permissions.application.controllers.student.create.enabled ?
						<Route end path="students" element={<StudentsList />}/>
						: ""
					}
					<Route end path="courses"                  element={<CourseList />} />
					<Route end path="wards"                    element={<Wards />} />
					<Route end path="students/:id/deployments" element={<DeploymentsEdit />} />
					<Route end path="students/:id/hours"       element={<HoursEdit />} />
					<Route end path="categories"               element={<CategoriesList />} />
					<Route end path="statistics/courses"       element={<StatsCourses />} />
					<Route end path="statistics/wards"         element={<StatsWards />} />
					<Route index                               element={<Navigate to="new" />} />
					<Route                                     element={<Spinner />} />
				</Routes>
			</>
		);
	}
}

export default PracticeChief;
