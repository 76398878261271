import React from "react";

const PageHeader = ({title, permission = true, textNew = null, actionNew=() => {}, Icon = null, children}) => {
	return (
		<header style={{marginBottom : "2rem"}}>
			<h2 className="py-4">
				{title}
				{permission && textNew ? 
					<button className="btn btn-success btn-sm float-right" onClick={() => actionNew()}>
						<Icon className="big" />
						{textNew}
					</button> : null
				}
			</h2>
			{children}
		</header>
	);
};

export default PageHeader;