import React, {useEffect, useState} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import SortableTable from "./SortableTable";
import Pagination    from "./Pagination";
import Spinner       from "./Spinner";

const PaginatedSortableTable = ({query, headers, numRows = 15, state = null, setState = null}) => {
	const alert             = useAlert();
	const [ready, setReady] = useState(false);
	const [data, setData]   = useState([]);
	const [count, setCount] = useState(0);
	const [start, setStart] = useState(0);
	const [sort, setSort]   = useState(null);

	const sortCallback = (member, direction) => {
		setSort({member, direction});
	}

	useEffect(() => {
		if (!query || query === "")
			return;
			
		const url      = new URL(process.env.REACT_APP_BACKEND_URL + query);
		const countURL = `${url.protocol}//${url.host}${url.pathname}/count?${url.searchParams.toString()}`;
		setReady(false);

		axios.get(countURL.toString()).then(response => {
			const count = response.data;
			setCount(count)

			url.searchParams.append("_limit", numRows);
			url.searchParams.append("_start", start);
			if (sort) {
				url.searchParams.delete("_sort");
				url.searchParams.append("_sort", `${sort.member}:${sort.direction}`);
			}

			axios.get(url.toString()).then(response => {
				if (state && setState)
					setState(response.data);
				else
					setData(response.data);
				setReady(true);
			}).catch(error => alert.error(`Fehler: ${error}`));
		}).catch(error => alert.error(`Fehler: ${error}`));

	}, [query, start, numRows, sort, alert, setState]);

	return (
		<>
		 	{ready ?
				<SortableTable headers={headers} data={state ? state : data} sortCallback={sortCallback} />
			: <Spinner />}
			<Pagination count={count} changePage={({start}) => setStart(start)} perPage={numRows} />
		</>
	);
};

const urlParamFilter = (fields, filterWord, queryString) => {
	const params = new URLSearchParams(queryString);

	for (let i = 0; i < fields.length; i++) {
		params.delete(`_where[_or][${i}][${fields[i]}_contains]`);
		params.append(`_where[_or][${i}][${fields[i]}_contains]`, filterWord);
	}

	return '?' + params.toString();
}

export {urlParamFilter};
export default PaginatedSortableTable;