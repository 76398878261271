import Header from "@editorjs/header";

import Std from "../Std";
import {AlignLeft, AlignCenter, AlignRight} from "../StringIcons";

class HeaderBlock extends Header {
	constructor(args) {
		super(args);
		const {data}      = args;
		this.alignment    = data?.alignment ? data.alignment : "left";
		this.block        = null;
		this.leftButton   = null;
		this.rightButton  = null;
		this.centerButton = null;
	}

	render() {
		this.block = super.render();
		this.block.style.textAlign = this.alignment;

		return this.block;
	}

	renderSettings() {
		this.leftButton = Std.CreateElement(`
			<article class="ce-settings__button">
				${AlignLeft}
			</article>
		`);
		this.centerButton = Std.CreateElement(`
			<article class="ce-settings__button">
				${AlignCenter}
			</article>
		`);
		this.rightButton = Std.CreateElement(`
			<article class="ce-settings__button" style="margin: 0">
				${AlignRight}
			</article>
		`);

		this.leftButton.addEventListener("click", () => {
			this.alignment             = "left";
			this.block.style.textAlign = this.alignment;
		});
		this.centerButton.addEventListener("click", () => {
			this.alignment             = "center";
			this.block.style.textAlign = this.alignment;
		});
		this.rightButton.addEventListener("click", () => {
			this.alignment             = "right";
			this.block.style.textAlign = this.alignment;
		});

		const ui = super.renderSettings();

		ui.append(this.leftButton);
		ui.append(this.centerButton);
		ui.append(this.rightButton);

		return ui;
	}

	save() {
		return {
			...this.data,
			alignment : this.alignment
		}
	}
}

export default HeaderBlock;
