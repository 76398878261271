class VariableStore {
	constructor() {
		this._variables        = [];
		this._groupedVariables = [];
	}

	get variables() {
		return this._variables;
	}

	get groupedVariables() {
		return this._groupedVariables;
	}

	register(id, label, value = null, group = null) {
		const regex      = /(\w+)@|at ?(\w+) ?\(/gm;///([a-zA-Z_-]+)\.jsx/gm;
		try {
			throw new Error();
		} catch (error) {
			const stackTrace = error.stack.toString();
			const result     = regex.exec(stackTrace);
			const groupName  = group || result[2] || result[1];
			if (!(this._groupedVariables[groupName] instanceof Array))
				this._groupedVariables[groupName] = [];
			this._groupedVariables[groupName].push({
				id,
				label,
				value
			});
			this._variables.push({
				id,
				label,
				value
			});
		}

		return this;
	}
}
VariableStore.Instance = null;

const Store = () => {
	return VariableStore.Instance || (VariableStore.Instance = new VariableStore());
};

export default Store;
