import React, {useEffect, useMemo, useState} from "react";

const DELTA = 1;

const Pagination = ({count, perPage = 10, changePage, back = "Vorherige", forwards = "Nächste"}) => {
	const [currentPage, setCurrentPage] = useState(1);

	const maxPages = useMemo(() => Math.ceil(count / perPage), [perPage, count]);

	useEffect(() => {
		changePage({start : (currentPage - 1) * perPage, limit : perPage});
	}, [currentPage, perPage, changePage]);

	const bullets = useMemo(() => {
		const left      = currentPage - DELTA;
		const right     = currentPage + DELTA + 1;
		const pages     = [];
		const pagesDots = [];
		let   l;

		for (let i = 1; i <= maxPages; i++) {
			if (i <= 2 || i >= maxPages - 1 || (i >= left && i < right))
				pages.push(i);
		}

		for (let i of pages) {
			if (l) {
				if (i - l === 2) {
					pagesDots.push(l + 1);
				} else if (i - l !== 1) {
					pagesDots.push('...');
				}
			}
			pagesDots.push(i);
			l = i;
		}

		return pagesDots;
	}, [currentPage, maxPages]);

	return (
		<nav>
			<ul className="pagination">
				<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
					<span className="page-link" onClick={event => setCurrentPage(currentPage - 1)}>{back}</span>
				</li>
				{
					maxPages >= 1 ? bullets.map((item, key) => {
						return <li key={key} className={`page-item ${currentPage === item ? "active" : ""} ${item === "..." ? "disabled" : ""}`}>
							<span className="page-link" onClick={() => item !== currentPage ? setCurrentPage(item) : null }>
								{item}
							</span>
						</li>
					}) : ""
				}
				<li className={`page-item ${currentPage === maxPages ? "disabled" : ""}`}>
					<span className="page-link" onClick={event => setCurrentPage(currentPage + 1)}>{forwards}</span>
				</li>
			</ul>
		</nav>
	);
};

export default Pagination;
