import React from "react";
import {Button} from "react-bootstrap";

import {Modal} from "react-bootstrap";

const YesNoModal = ({text, callback, show, setShow, title = "Löschen"}) => {
	return (
		<Modal show={show}>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{text}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-danger" onClick={() => setShow(false)}>Abbrechen</Button>
				<Button variant="primary" onClick={() => {
					callback();
					setShow(false);
				}}>OK</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default YesNoModal;
