import React from "react";

const StudentSelect = ({data, onChange, ...args}) => {
	return (
		<select className="form-control" onChange={event => {
			event.persist();
			onChange(event);
		}} {...args}>
			<option disabled value={-1}>Auszubildende/n auswählen</option>
			{
				data.map(student => {
					return <option key={student.id} value={student.id}>{student.lastname} {student.firstname}</option>
				})
			}
		</select>
	);
};

export default StudentSelect;
