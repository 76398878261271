import React from "react";

const CourseSelect = ({data, onChange}) => {
	return (
		<select className="form-control" onChange={onChange} defaultValue={-1}>
			<option value="-1" disabled>Kurs auswählen</option>
			{
				data.map(course => {
					return <option key={course.id} value={course.id}>{course.name} [{course.year}]</option>
				})

			}
		</select>
	);
};

export default CourseSelect;
