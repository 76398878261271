import {StrapiDate} from "../utils/StrapiUtils";

const DateFormatter = value => {
	return new Date(Date.parse(value)).toLocaleString("de-DE", {
		month         : "long",
		formatMatcher : "basic",
		day           : "2-digit",
		year          : "numeric"
	});
};

const ShortDateFormatter = value => {
	return new Date(Date.parse(value)).toLocaleString("de-DE", {
		month         : "2-digit",
		formatMatcher : "basic",
		day           : "2-digit",
		year          : "numeric"
	});
};

const ShortDateTimeFormatter = value => {
	return new Date(Date.parse(value)).toLocaleString("de-DE", {
		month         : "2-digit",
		formatMatcher : "basic",
		day           : "2-digit",
		year          : "numeric",
		hour          : "numeric",
		minute        : "numeric"
	}).replace(", ", " um ") + " Uhr";
}

const StringTimeFormatter = value => {
	const today = new StrapiDate(Date.now());
	const date  = new StrapiDate(value);
	const diff  = today.differenceInDays(date);
	if (diff <= 2) {
		const string = diff === 0 ? "Heute" : (diff === 1 ? "Gestern" : "Vorgestern");
		return string + " um " + date.toLocaleString("de-DE", {
			hour          : "numeric",
			minute        : "numeric"
		}).replace(", ", " um ") + " Uhr";
	} else {
		return  ShortDateTimeFormatter(value);
	}
};

export {ShortDateFormatter, ShortDateTimeFormatter, StringTimeFormatter};
export default DateFormatter;
