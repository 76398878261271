
import React, {useEffect, useRef} from "react";
import {Search, X} from "react-bootstrap-icons";

const SearchField = ({search = "", setSearch}) => {
	const field = useRef();

	useEffect(() => {
		const input = field.current;

		const listener = event => {
			if (event.key === "Delete" && document.activeElement === input) 
				setSearch("");
			if (event.key !== "f" || (!event.ctrlKey && !event.metaKey))
				return;
			input.focus();
		};

		document.addEventListener("keydown", listener);

		return () => {
			document.removeEventListener("keydown", listener);
		}
	}, [setSearch]);

	return (
		<>
			<div className="input-group">
				<div className="input-group-prepend">
					<div className="input-group-text"><Search /></div>
				</div>
				<input type="text" className="form-control" placeholder="Suche..." value={search} onChange={event => setSearch(event.target.value)} ref={field} />
				{
					search.length > 0 ?
						<button type="button" className="btn bg-transparent" style={{marginLeft: "-40px", zIndex: 100}} onClick={() => setSearch("")}>
							<X />
						</button> : null
				}
			</div>
		</>
	);
};

export default SearchField;
