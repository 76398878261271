import Std from "../Std";

import {ArrowsExpand} from "../StringIcons";

class SpacerBlock {
	constructor({data}) {
		this.data   = data;
		this.height = data?.height? data.height : 5;
		this.block  = null;
		this.input  = null;
	}

	render() {
		this.block = Std.CreateElement(`
			<div class="block-spacer" style="height: ${this.height}mm"></div>
		`);

		return this.block;
	}

	renderSettings() {
		this.input = Std.CreateElement(`
				<input class="form-control" type="number" step="5" value="${this.height}" />
		`);
		this.input.addEventListener("change", event => {
			this.height = parseInt(event.target.value);
			this.block.style.height = `${this.height}mm`;
		});
		const ui = Std.CreateElement(`
			<article class="block-spacer-settings">
				<label>Abstand</label>
			</article>
		`);

		ui.append(this.input);

		return ui;
	}

	save() {
		return {
			height : this.height
		}
	}

	static get toolbox() {
		return {
			title : "Spacer",
			icon  : ArrowsExpand
		}
	}
}

export default SpacerBlock;
