import React, {useState, useEffect, useContext, useCallback} from "react";
import axios from "axios";

import {useAlert}  from "react-alert";
import Papa        from "papaparse";
import {ThreeDots} from "react-bootstrap-icons";

import Std           from "../../../utils/Std";
import Spinner       from "../../../components/shared/Spinner";
import UserContext   from "../../../components/UserContext";
import DateFormatter from "../../../formatters/DateFormatter";
import SortableTable from "../../../components/shared/SortableTable";

const Export = () => {
	const {permissions}               = useContext(UserContext);
	const alert                       = useAlert();
	const [ready, setReady]           = useState(false);
	const [tables, setTables]         = useState([]);
	const [table, setTable]           = useState("");
	const [headers, setHeaders]       = useState({});
	const [sampleData, setSampleData] = useState([]);

	useEffect(() => {
		axios.get("/apireflection").then(response => {
			setTables(response.data);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Tabellen: ${error}`));
	}, [alert]);

	useEffect(() => {
		if (!table || table === "")
			return;
		setHeaders([]);
		axios.get(`/apireflection/${table}`).then(response => {
			console.log(response.data);
			const newHeaders = [
				{
					label  : "Id",
					member : "id",
					width  : "90px"
				}
			];
			for (const [key, value] of Object.entries(response.data.attributes)) {
				const headerObject = {
					label    : key,
					member   : key,
					sortable : false
				};
				switch (value.type) {
					case "date":
						headerObject.formatter = DateFormatter;
						break;
					default:
						break;
				}
				if (value.hasOwnProperty("model"))
					headerObject.member = `${headerObject.member}.id`;
				newHeaders.push(headerObject);
			}
			setHeaders(newHeaders);
			axios.get(`/${Std.GetAPIName(table)}?_limit=5`).then(response => {
				setSampleData(response.data);
			}).catch(error => alert.error(`Fehler beim Laden der Tabellenvorschau für Tabelle "${table}": ${error}`));
		}).catch(error => alert.error(`Fehler beim Laden der Tabellenstruktur für Tabelle "${table}": ${error}`));
	}, [table, alert]);

	const performExport = useCallback(() => {
		axios.get(`/${Std.GetAPIName(table)}?_limit=-1`).then(response => {
			const data = response.data.map(item => {
				for (const member of Object.keys(item)) {
					if (member.charAt(0) === "_") {
						delete item[member];
						continue;
					}
					if (typeof item[member] === "object") {
						item[member] = item[member]?.id;
					}
				}

				return item;
			});
			const result = Papa.unparse(data, {
				delimiter : ";"
			});

			save(`${table}.csv`, result);
			setTable("");
		}).catch(error => alert.error(`Fehler beim Exportieren der Tabelle '${table}: ${error}'`));
	}, [table, alert]);

	const save = (filename, data) => {
		const blob = new Blob([data], {type: "text/csv"});
		if(window.navigator.msSaveOrOpenBlob)
			window.navigator.msSaveBlob(blob, filename);
		else {
			const element    = window.document.createElement("a");
			element.href     = window.URL.createObjectURL(blob);
			element.download = filename;
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}
	}

	if (!ready) {
		return (
			<>
				<h2 className="py-4">Export</h2>
				<Spinner />
			</>
		)
	}

	return (
		<>
			<h2 className="py-4">Export</h2>
			<section className="alert alert-info">
				Sie können hier alle Tabellen auf die Sie Zugriff haben als <code>.csv</code>-Datei exportieren
				und auf Ihrem PC speichern.
			</section>
			<section className="grid three-thirds">
				<p>
					<select value={table} onChange={event => setTable(event.target.value)} className="form-control">
						<option value="" disabled>Tabelle auswählen...</option>
						{tables.map(item => {
							const disabled = !permissions.application.controllers[item]?.find.enabled;
							return <option value={item} key={item} disabled={disabled}>{item}</option>
						})}
					</select>
				</p>
				<p>
					<button className="btn btn-primary" onClick={performExport} disabled={table === ""}>Exportieren</button>
				</p>
			</section>
			{
				headers.length > 0 ?
					<>
						<h4 className="my-4">Beispielinhalte für Tabelle <code>{table}</code></h4>
						<section className="preview-table" style={{opacity : 0.4}}>
							<SortableTable headers={headers} data={sampleData} footer={
								<tr>
									{ headers.map((_, index) => <td key={index}><ThreeDots className="big" /></td>) }
								</tr>
							} />
						</section>
					</> : ""
			}
		</>
	)
};

export default Export;
