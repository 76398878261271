import React, {useContext, useEffect, useState} from "react";

import {Routes, Route, NavLink} from "react-router-dom";
import {BoxArrowInDown} from "react-bootstrap-icons";

import UserContext from "../../components/UserContext";

import Import from "./components/Import";
import Export from "./components/Export";

import "./_styles.scss";

const Importer = ({ type }) => {
	const {permissions}     = useContext(UserContext);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (typeof permissions.application !== "undefined")
			setReady(true);
	}, [permissions]);

	if (!permissions.apireflection?.controllers.apireflection.find.enabled)
		return <></>;

	if (!ready)
		return <></>

	if (type === "nav") {
		return (
			<NavLink className={({isActive}) => isActive ? "active" : ""} to="/Importer"><BoxArrowInDown /> <span>Import/Export</span></NavLink>
		);
	}

	return (
		<>
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/Importer">Import</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="/Importer/export">Export</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route index element={<Import />} />
				<Route end path="export" element={<Export />} />
			</Routes>
		</>
	);
};

export default Importer;
