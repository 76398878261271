import React, {useState, useEffect, useRef} from "react";
import axios from "axios";

import {useParams}           from "react-router";
import {useAlert}            from "react-alert"
import {createReactEditorJS} from "react-editor-js";
import {Search}              from "react-bootstrap-icons";
import {Form}                from "react-bootstrap";

import list        from "@editorjs/list";

import Spinner                     from "../../../components/shared/Spinner";
import VariableBlock               from "../../../utils/editor/VariableBlock";
import SpacerBlock                 from "../../../utils/editor/SpacerBlock";
import HLineBlock                  from "../../../utils/editor/HLineBlock";
import ColumnsBlock                from "../../../utils/editor/ColumnsBlock";
import HeaderBlock                 from "../../../utils/editor/HeaderBlock";
import ParagraphBlock              from "../../../utils/editor/ParagraphBlock";
import TabularDataPlaceholderBlock from "../../../utils/editor/TabularDataPlaceholderBlock";
import Base64ImageBlock            from "../../../utils/editor/Base64ImageBlock";

import PDFRenderer from "../../../utils/editor/PDFRenderer";

const EditorJS = createReactEditorJS();

const EditTemplate = ({match}) => {
	const {id}                    = useParams();
	const editor                  = useRef();
	const alert                   = useAlert();
	const [ready, setReady]       = useState(false);
	const [template, setTemplate] = useState(null);

	useEffect(() => {
		axios.get(`/templates/${id}`).then(response => {
			setTemplate(response.data);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Vorlage: ${error}`));
	}, [match, alert]);

	const save = async () => {
		console.log(await editor.current.save());
		const content = JSON.stringify(await editor.current.save());

		axios.put(`/templates/${template?.id}`, {
			...template,
			content
		}).then(() => {
			alert.success("Die Vorlage wurde gespeichert");
		}).catch(error => alert.error(`Fehler beim Speichern der Vorlage: ${error}`));
	}

	const handleInput = event => {
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		let newData = {...template};
		newData[event.target.name] = value;
		setTemplate(newData);
	};

	if (!ready)
		return (
			<>
				<h2 className="py-4">Template bearbeiten</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<section className="grid seventy-thirty" style={{paddingRight : 0, paddingTop : 0}}>
				<section>
					<h2 className="py-4">Template bearbeiten</h2>
					<article className="template-editor" style={{
						paddingTop : `${template.ptop}mm`,
						paddingRight: `${template.pright}mm`,
						paddingBottom : `${template.pbottom}mm`,
						paddingLeft : `${template.pleft}mm`,
					}}>
						<EditorJS data={JSON.parse(template?.content)} tools={{
							list : {
								class : list,
								inlineToolbar : ["variable"]
							},
							paragraph : {
								class         : ParagraphBlock,
								inlineToolbar : ["variable"]
							},
							header : {
								class         : HeaderBlock,
								inlineToolbar : ["variable"]
							},
							spacer   : SpacerBlock,
							hline    : HLineBlock,
							columns  : ColumnsBlock,
							image    : Base64ImageBlock,
							variable : VariableBlock,
							tabular  : TabularDataPlaceholderBlock
						}} instanceRef={instance => editor.current = instance} />
						<small className="pagination" style={{
							bottom  : `${template.pbottom}mm`,
							right   : `${template.pright}mm`,
							display : template.pagination ? "block" : "none"
						}}>Seite 1 von 1</small>
					</article>
				</section>
				<aside className="editor">
					<p>
						<button className="btn btn-outline-primary" onClick={async () => {
							const doc = PDFRenderer(template, await editor.current.save());
							doc.show("preview");
						}}>
							<Search style={{margin : 0}} />
						</button> &nbsp;
						<button className="btn btn-success" onClick={save}>
							Speichern
						</button>
					</p>
					<span className="section-title">Templatename</span>
					<p>
						<input
							type="text" name="name" className="form-control"
							placeholder="Name" value={template?.name} onChange={handleInput} />
					</p>
					<span className="section-title">Seitenränder</span>
					<section className="grid half-half" style={{maxWidth : "30rem", margin : "0 auto"}}>
						<article className="page-margin">
							<label>Oben</label>
							<div className="input-group mb-3">
								<input type="number" max={50} className="form-control" name="ptop" value={template?.ptop} onChange={handleInput} />
								<div className="input-group-append">
									<span className="input-group-text">mm</span>
								</div>
							</div>
						</article>
						<article className="page-margin">
							<label>Rechts</label>
							<div className="input-group mb-3">
								<input type="number" max={50} className="form-control" name="pright" value={template?.pright} onChange={handleInput} />
								<div className="input-group-append">
									<span className="input-group-text">mm</span>
								</div>
							</div>
						</article>
						<article className="page-margin">
							<label>Unten</label>
							<div className="input-group mb-3">
								<input type="number" max={50} className="form-control" name="pbottom" value={template?.pbottom} onChange={handleInput} />
								<div className="input-group-append">
									<span className="input-group-text">mm</span>
								</div>
							</div>
						</article>
						<article className="page-margin">
							<label>Links</label>
							<div className="input-group mb-3">
								<input type="number" max={50} className="form-control" name="pleft" value={template?.pleft} onChange={handleInput} />
								<div className="input-group-append">
									<span className="input-group-text">mm</span>
								</div>
							</div>
						</article>
					</section>
					<hr />
					<span className="section-title">Einstellungen</span>
					<div className="p">
						<Form.Check type="switch" name="pagination" onChange={handleInput} checked={template?.pagination} label="Seitenzahlen" id="pagination" />
					</div>
					<div className="p">
						<Form.Check type="switch" name="index" onChange={handleInput} checked={template?.index} label="Inhaltsverzeichnis" id="index" />
					</div>
				</aside>
			</section>
		</>
	);
};

export default EditTemplate;
