import {useContext} from "react";

import UserContext from "components/UserContext";

const usePermission = (controller, permission, module = null) => {
	const {permissions} = useContext(UserContext);

	if (module)
		return permissions[module]?.controllers[controller][permission]?.enabled === true;
	
	return permissions?.application?.controllers[controller][permission]?.enabled === true;
};

export default usePermission;