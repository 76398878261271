import {useEffect, useState} from "react";

const useLocalStorage = (storageString, defaultValue = null) => {
	let initialValue;
	try {
		initialValue = JSON.parse(localStorage.getItem(storageString));
	} catch (exception) {
		initialValue = localStorage.getItem(storageString);
	}
	initialValue = initialValue === null && defaultValue !== null ? defaultValue : initialValue;
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		let storageValue;
		try {
			storageValue = JSON.stringify(value);
		} catch (exception) {
			storageValue = value;
		}
		localStorage.setItem(storageString, storageValue);
	}, [value, storageString]);

	return [value, setValue];
};

export default useLocalStorage;
