import React, {useState} from "react";
import axios             from "axios";
import {useParams}       from "react-router";
import {useAlert}        from "react-alert";
import {Link}            from "react-router-dom";

const ResetPassword = ({darkMode}) => {
	const code                                            = useParams().code;
	const alert                                           = useAlert();
	const [success, setSuccess]                           = useState(false);
	const [password, setPassword]                         = useState(null);
	const [passwordConfirmation, setPasswordConfirmation] = useState(null);

	const resetPassword = event => {
		event.preventDefault();

		if (password !== passwordConfirmation) {
			alert.error("Die eingegebenen Passwörter stimmen nicht überein.");

			return;
		}

		axios.post(`/auth/reset-password`, {
			code,
			password,
			passwordConfirmation
		}).then(response => {
			setSuccess(true);
			alert.success(`Glückwunsch. Sie haben jetzt ein neues Passwort.`)
		})
			.catch(error => alert.error(`Es ist ein Fehler beim setzen des Passworts aufgetreten.`));
	}

	return (
		<div className="wrapper login">
			<section className="card login-form p-5">
				<div className="card-body p-0">
					<header>
						{darkMode ?
							<h4 className="text-center"><img src="/logo_marvolo_light.svg" alt="marvolo." /></h4>
						: <h4 className="text-center"><img src="/logo_marvolo_dark.svg" alt="marvolo." /></h4>
						}
					</header>
					<div className="row">
						<div className="col-md-12">
							<h4>neues Passwort vergeben</h4>
							<br />
							<p>
								<small>
									Bitte geben Sie ein neues Passwort ein.
								</small>
							</p>
							<form onSubmit={resetPassword}>
								<p>
									<input type="password" name="password" placeholder="neues Passwort" className="form-control" onChange={event => setPassword(event.target.value)} required disabled={success} />
								</p>
								<p>
									<input type="password" name="password2" placeholder="neues Passwort wiederholen" className="form-control" onChange={event => setPasswordConfirmation(event.target.value)} required disabled={success} />
								</p>
								<p>
									<button className="btn btn-primary full" disabled={success}>Passwort ändern</button>
								</p>
								{
									success ?
										<p>
											<Link to="/" className="btn btn-outline-primary">zurück zum Login</Link>
										</p>
										: ""
								}
							</form>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ResetPassword;
