import jsPDF from "jspdf";
import "jspdf-autotable";

/**
 * Holds the data of a base64 encoded image
 *
 * @author Michael Ochmann <michael.ochmann@propeller.de>
 */
class PDFImage {
	/**
	 * @param {string} data - the base64 encoded representation of the image
	 * @param {number} width - overrides the real width of the image for presentation
	 * @param {number} height - override the real height if the image for presentation
	 */
	constructor(data, width = null, height = null) {
		this.data        = data;
		this.width       = width;
		this.height      = height;
		this.initialized = false;
	}

	_init(jsPDF) {
		const info  = jsPDF.getImageProperties(this.data);
		this.width  = this.width === null  ? PDFImage.PxToMM(info.width, jsPDF.dpi)  : this.width;
		this.height = this.height === null ? PDFImage.PxToMM(info.height, jsPDF.dpi) : this.height;
	}

	static PxToMM(px, dpi) {
		return 25.4 / dpi * px;
	}
}

/**
 * Abstraction for rudimentary auto layouting with jsPDF
 *
 * @author Michael Ochmann <michael.ochmann@propeller.de>
 * @since 0.0.2
 */
class PDF {
	/**
	 * @param {object} margins - The page margins for the document, defined by `top`, `right`, `bottom` and `left` properties
	 * @param papersize - The papersize to use for the document, default `a4`
	 */
	constructor(margins = {}, papersize = "a4") {
		Object.assign(this, new jsPDF("p", "mm", papersize));
		this.margins = {
			top    : 20,
			right  : 25,
			bottom : 15,
			left   : 25,
			...margins
		}
		this.bounds = {
			width  : this.internal.pageSize.width,
			height : this.internal.pageSize.height
		};
		this.dpi                   = 300;
		this.pages                 = 1;
		this.currentPage           = 1;
		this._x                    = this.margins.left;
		this._y                    = this.margins.top;
		this._currentImageX        = 0;
		this._currentImageMaxY     = 0;
		this._currentImageWidth    = 0;
		this._currentImageAlign    = null;
		this._columns              = 0;
		this._currentColumn        = 0;
		this._currentColumnsOnPage = 1;
		this._lastPageForColumns   = 1;
		this._columnSpacing         = 10;
		this._yBuffer              = this._y;
		this._maxY                 = this._y;
		this.pagination            = true;
		this._inList               = false;

		this._headings             = [];
		this._subheadings          = [];
		this._subsubheadings       = [];
		this._currentHeading       = 0;
		this._currentSubheading    = 0;
	}

	/**
	 * Draws the pagenumbers in the bottom right corner of every page
	 *
	 * @param page
	 * @private
	 */
	_drawPagination(page = 1) {
		if (!this.pagination)
			return;
		const pageBuffer = this.currentPage;
		this.setPage(page);
		this.setFontSize(6);
		this.text(
			`Seite ${page} von ${this.pages}`,
			this.bounds.width - this.margins.right,
			this.bounds.height - this.margins.bottom,
			{
				align    : "right",
				baseline : "bottom"
			}
		);
		this.setPage(pageBuffer);
	}

	/**
	 * @param {array} args - draws a table utilizing the `jspdf-autotable` plugin
	 * @returns {PDF}
	 */
	tabular(...args) {
		this.autoTable({
			...args[0],
			startY : this._y,
			margin : {
				left   : this.margins.left,
				right  : this.margins.right,
				bottom : 25,
			},
			didDrawPage : data => {
				const {pageCount, pageNumber} = data;
				this.pages = pageCount;
				this.setPage(pageNumber);
			}
		});
		this.y(this.previousAutoTable.finalY + PDF.PtToMM(this.getFontSize()) * this.getLineHeightFactor());
		this.currentPage = this.currentPage + this.lastAutoTable.pageCount - 1;

		return this;
	}

	/**
	 * Draws a section heading. For internal use
	 *
	 * @param text
	 * @param align
	 * @param vspace
	 * @param fontSize
	 * @returns {number}
	 */
	heading(text, align = PDF.Align.CENTER, vspace = 8, fontSize = 16){
		const x    = this.x();
		let halign = "";
		this.setFontSize(fontSize || 16);

		switch (align) {
			default:
			case PDF.Align.LEFT:
				halign = "left";
				break;
			case PDF.Align.RIGHT:
				halign = "right";
				this.x(this.bounds.width - this.margins.right);
				break;
			case PDF.Align.CENTER:
				halign = "center";
				this.x(this.bounds.width / 2);
				break;
		}

		this.write(text, fontSize, vspace, {
			align : halign
		});
		this.x(x);

		return this.currentPage;
	}

	h1(text, align = PDF.Align.LEFT, vspace = 8) {
		const page = this.heading(text, align, vspace);
		this._currentHeading++;
		this._headings.push({
			text,
			page,
			id : this._currentHeading
		});

		return this;
	}

	h2(text, align = PDF.Align.LEFT, vspace = 8) {
		const page = this.heading(text, align, vspace, 14);
		this._currentSubheading++;
		this._subheadings.push({
			text,
			page,
			id     : this._currentSubheading,
			parent : this._currentHeading
		});

		return this;
	}

	h3(text, align = PDF.Align.LEFT, vspace = 8) {
		const page = this.heading(text, align, vspace, 12);
		this._subsubheadings.push({
			text,
			page,
			parent : this._currentSubheading
		});

		return this;
	}

	vspace(space) {
		this.y(this._y + space);

		return this;
	}

	image(image, vspace = 0, float = PDF.Align.LEFT, filetype = "PNG") {
		if (!(image instanceof PDFImage)) {
			console.error(`Images to put into your PDF must be of type 'PDFImage'`);
			return;
		}
		if (!image.initialized)
			image._init(this);

		this._currentImageWidth = image.width;
		this._currentImageAlign = float;
		const x = float === null || float === PDF.Align.LEFT || float === PDF.Align.BLOCK ? this.columnX() : (float === PDF.Align.CENTER ? (this.columnX() + this.columnWidth() / 2) - image.width / 2 : (this.columnX() + this.columnWidth()) - image.width);
		this.addImage(image.data, filetype, x, this._y + vspace, image.width, image.height);

		if (float === PDF.Align.BLOCK)
			this._y += image.height + vspace + 5;

		if (float === null || float === PDF.Align.BLOCK)
			return;

		switch (float) {
			default:
			case PDF.Align.LEFT:
				this._currentImageX    = image.width + 5;
				this._currentImageMaxY = this._y + image.height + vspace + 5;
				break;
			case PDF.Align.RIGHT:
				this._currentImageX    = (image.width + 5) * -1;
				this._currentImageMaxY = this._y + image.height + vspace + 5;
				break;
		}
	}

	write(text, fontSize = 10, vspace = 0, args) {
		this.setFontSize(fontSize || 10);
		const height = this.textHeight(text);
		this.y(this._y + vspace);
		if (this._y >= this._currentImageMaxY) {
			this._currentImageX     = 0;
			this._currentImageMaxY  = 0;
			this._currentImageWidth = 0;
			this._currentImageAlign = null;
		}

		if (this._y + height > this.bounds.height - this.margins.bottom)
			this.nextPage();

		const x = this.columnX() + (this._currentImageAlign === PDF.Align.RIGHT ? 0 : this._currentImageX);
		this.text(text, x, this._y, {
			...args,
			maxWidth : this._columns !== 0 ? this.columnWidth() - this._columnSpacing - this._currentImageWidth : this.bounds.width - this.margins.left - this.margins.right - this._currentImageWidth,
			baseline : "top"
		});
		this.y(this._y + height);

		return this;
	}

	nextPage() {
		if (this.pages > this.currentPage) {
			this.currentPage++;
			this.setPage(this.currentPage);
		} else {
			this.pages++;
			this.currentPage++;
			this.addPage();
		}
		if (this._columns !== 0) {
			this._lastPageForColumns = this.currentPage;
			this.y(this.margins.top);
			this._maxY = 0;
			return;
		}
		this.y(this.margins.top);
		this.x(!this._inList ? this.margins.left : this._x);

		return this;
	}

	hline(color = "#000000", width = 0.1 , length = null) {
		this.setLineWidth(width);
		this.setDrawColor(color);

		const x   = this._columns > 0 ? this._x + this._currentColumn * (this.columnWidth() + this._columnSpacing) : this._x;
		const len = this._columns === 0 ?
			this.bounds.width - this.margins.left - this.margins.right :
			this.columnWidth() - this._columnSpacing;
		length = length === null ? len : length;
		this.line(x, this._y, x + length, this._y);
		this.y(this._y + PDF.PtToMM(this.getFontSize() * this.getLineHeightFactor()));

		return this;
	}

	list(items, fontSize = 10, padding = 0, numeric) {
		const xBuffer       = this._x;
		const circleX       = this.columnX() + 0.5;
		const spacing       = fontSize * (3 / 10)
		const numericFactor = this.columnX() + items.length.toString().length * spacing;
		let i = 1;

		this._inList = true;
		this._x     += numeric ? items.length.toString().length * spacing + 1 : spacing;
		for (const item of items) {
			this.write(item, fontSize, padding);
			if (!numeric)
				this.circle(circleX, this._y - this.textHeight(item) + PDF.PtToMM(fontSize) / 2 + padding, 0.5, "F");
			else {
				this.setFontSize(fontSize);
				this.text(`${i}.`, numericFactor, this._y + padding - this.textHeight(item), {
					baseline : "top",
					align    : "right"
				});
			}
			i++;
		}
		this.x(xBuffer);
		this._inList = false;

		return this;
	}

	orderedList(items, fontSize = 10, padding = 0) {
		this.list(items, fontSize, padding, true);
	}

	columnWidth() {
		const width = this.bounds.width - this.margins.left - this.margins.right;
		if (this._columns <= 0)
			return width;

		return width / this._columns;
	}

	startColumns(count, columnSpacing = 10) {
		this._columns              = count;
		this._columnSpacing        = columnSpacing;
		this._yBuffer              = this._y;
		this._currentColumnsOnPage = this.currentPage;
		this._lastPageForColumns   = this.currentPage;
	}

	nextColumn() {
		if (++this._currentColumn >= this._columns)
			return;
		this.y(this._yBuffer);
		this.setPage(this._currentColumnsOnPage);
		this.currentPage = this._currentColumnsOnPage;
	}

	endColumns() {
		this._columns       = 0;
		this._currentColumn = 0;
		this.y(this._maxY)
		this._maxY          = 0;
		this.setPage(this._lastPageForColumns);

		return this;
	}

	columnX() {
		return this._columns > 0 ? this._x + this._currentColumn * (this.columnWidth() + this._columnSpacing) : this._x;
	}

	x(width = null) {
		if (width === null)
			return this._x;
		this._x = width;
	}

	y(height = null) {
		if (height === null)
			return this._y;
		this._y    = height;
		this._maxY = this._y > this._maxY ? this._y : this._maxY;
	}

	textHeight(text) {
		const lines = this.splitTextToSize(
			text,
			this._columns === 0 ?
				this.bounds.width - this.margins.left - this.margins.right :
				this.columnWidth() - this.columnSpacing
		).length;

		return lines * PDF.PtToMM(this.getFontSize() * this.getLineHeightFactor());
	}

	show(filename = "document.pdf") {
		for (let i = 1; i <= this.pages; i++)
			this._drawPagination(i);
		window.open(this.output("bloburl", {
				filename : filename
		}));
	}

	_writeIndexLine(heading, pageOffset, padding) {
		const fontSize   = heading.parent ? 9 : 11;
		const actualPage = heading.page >= pageOffset ? heading.page + 1 : heading.page;

		this.setFontSize(9);
		this.setLineDashPattern([0.2, 1]);

		this.text(actualPage.toString(), this.bounds.width - this.margins.right, this._y + padding * 2, {
			align    : "right",
			baseLine : "top"
		});

		const boundsText    = this.getTextDimensions(heading.text);
		const boundsPage    = this.getTextDimensions(actualPage.toString());

		this.write(heading.text.trim(), fontSize, padding);
		this.line(
			this._x + boundsText.w + 2,
			this._y - 1,
			this.bounds.width - this.margins.right - boundsPage.w - 2,
			this._y - 1
		);
		this.setLineDashPattern([]);
	}

	makeIndex(beforePage = 1, title = "Inhaltsverzeichnis") {
		const padding = 3;
		const bufferY = this._y;
		const bufferX = this._x;
		this.insertPage(beforePage);
		this.pages++;
		this.y(this.margins.top);
		this.x(this.margins.left);

		this.heading(title);
		this.vspace(20);

		for (const heading of this._headings) {
			this._writeIndexLine(heading, beforePage, padding);
			this._x += 6;
			for (const subheading of this._subheadings.filter(subheading => subheading.parent === heading.id)) {
				this._writeIndexLine(subheading, beforePage, padding);
				this._x += 6;
				for (const subsubheading of this._subsubheadings.filter(subsubheading => subsubheading.parent === subheading.id))
					this._writeIndexLine(subsubheading, beforePage, padding);
				this._x -= 6;
			}
			this._x -= 6;
		}

		this.y(bufferY);
		this.x(bufferX);
	}

	static PtToMM(pt) {
		return pt / 2.835;
	}
}
PDF.Align = Object.freeze({
	LEFT   : 1,
	CENTER : 2,
	RIGHT  : 3,
	BLOCK  : 4
});

export var Align    = PDF.Align;
export {PDFImage};
export default PDF;
