import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";

import {NavLink, useParams}                    from "react-router-dom";
import {ChevronLeft, Pencil, Briefcase, Trash} from "react-bootstrap-icons";
import {useAlert}                              from "react-alert";

import Spinner       from "../../../components/shared/Spinner";
import SortableTable from "../../../components/shared/SortableTable";
import DateFormatter from "../../../formatters/DateFormatter";

import DeploymentEdit from "./modals/DeploymentEdit";
import YesNoModal from "../../../components/shared/YesNoModal";

const DeploymentsEdit = () => {
	const headers = [
		{
			label  : "Id",
			member : "id",
			width  : "100px"
		},
		{
			label  : "Einsatzort",
			member : "ward.name"
		},
		{
			label     : "von",
			member    : "from",
			formatter : DateFormatter
		},
		{
			label     : "bis",
			member    : "to",
			formatter : DateFormatter
		},
		{
			label    : "Sollstunden",
			member   : "hoursTotal",
			sortable : false
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {
							setDeployment(member);
							setShow(true);
						}}><Pencil /></button>
						<button className="btn" onClick={() => {
							setDeployment(member);
							setShowDelete(true);
						}}><Trash /></button>
					</>
				)
			},
			width      : "90px"
		}
	];

	const {id}                          = useParams();
	const alert                         = useAlert();
	const [ready, setReady]             = useState(false);
	const [student, setStudent]         = useState(null);
	const [deployments, setDeployments] = useState([]);
	const [deployment, setDeployment]   = useState(null);
	const [show, setShow]               = useState(false);
	const [showDelete, setShowDelete]   = useState(false);
	const [wards, setWards]             = useState([]);

	const load = useCallback(() => {
		axios.get(`/students/${id}`).then(response => {
			setStudent(response.data);
			axios.get(`/deployments?_sort=from:DESC&student=${id}`).then(response => {
				setDeployments(response.data);
				setReady(true);
			}).catch(error => alert.error(`Fehler beim Laden der Einsätze: ${error}`));
		}).catch(error => alert.error(`Fehler beim Laden der Daten des Auszubildenden: ${error}`));
		axios.get("/wards").then(response => setWards(response.data))
			.catch(error => alert.error(`Fehler beim Laden der Einsatzorte: ${error}`));
	}, [alert, id]);

	useEffect(() => {
		load();
	}, [load]);

	const del = () => {
		axios.delete(`/deployments/${deployment.id}`).then(() => {
			alert.success("Der Eintrag wurde erfolgreich gelöscht");
		}).catch(error => alert.error(`Fehler beim Löschen des Einsatzes: ${error}`));
		load();
	}

	if (!ready)
		return (
			<>
				<h2 className="py-4">
					<button className="btn"><ChevronLeft className="big" /></button>
					<span style={{verticalAlign : "middle"}}>
					Einsätze
				</span>
				</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<h2 className="py-4">
				<NavLink to="/PracticeChief/students">
					<button className="btn"><ChevronLeft className="big" /></button>
				</NavLink>
				<span style={{verticalAlign : "middle"}}>
					Einsätze für {student.firstname} {student.lastname}
				</span>
				<button className="btn btn-success btn-sm float-right" onClick={() => { setDeployment(null); setShow(true); }}>
					<Briefcase className="big" />
					Einsatz anlegen
				</button>
			</h2>
			<SortableTable data={deployments} headers={headers} />
			<DeploymentEdit show={show} setShow={setShow} deployments={deployments} deployment={deployment} wards={wards} onChange={() => load()} student={student} />
			<YesNoModal
				show={showDelete}
				setShow={setShowDelete}
				callback={() => del()}
				text={`Möchten Sie den Einsatz mit der Id '${deployment?.id}' wirklich löschen?`}
				title="Einsatz löschen"/>
		</>
	);
}

export default DeploymentsEdit;
