import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";

import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap"

import {useAlert} from "react-alert";
import {Form} from "react-bootstrap";

import Std     from "../../../utils/Std";
import Spinner from "../../../components/shared/Spinner";

const ImportModal = ({show, setShow, model, data, table}) => {
	const alert                               = useAlert();
	const [mappings, setMappings]             = useState([]);
	const [ignoreFirstRow, setIgnoreFirstRow] = useState(true);
	const [importing, setImporting]           = useState(false);

	const changeMappings = (row, value) => {
		if (!value || value === "")
			return;

		const newMappings = [...mappings];
		newMappings[row] = parseInt(value);
		setMappings(newMappings);
	};

	const startImport = useCallback(() => {
		if (!model) {
			alert.error(`Es wurde kein gültiger Inhaltstyp übergeben`);
			return;
		}
		setImporting(true);
		const promises = [];
		let itemId     = 0;

		for (const item of data) {
			if (itemId === 0 && ignoreFirstRow) {
				itemId++;
				continue;
			}
			const newItem = {};

			let index = 0;
			for (const attr in model.attributes) {
				if (item[mappings[index]] === null || typeof item[mappings[index]] === "undefined") {
					index++;
					continue;
				}
				const curVal  = item[mappings[index]];
				newItem[attr] = model.attributes[attr].type === "boolean" ? curVal === "true" : curVal;
				switch (model.attributes[attr].type) {
					case "boolean":
						newItem[attr] = curVal === "true";
						break;
					case "integer":
						newItem[attr] = parseInt(curVal);
						break;
					case "decimal":
						newItem[attr] = parseFloat(curVal);
						break;
					default:
						newItem[attr] = curVal;
						break;
				}

				index++;
			}

			promises.push(axios.post(`/${Std.GetAPIName(table)}`, newItem));
		}

		Promise.all(promises).then(() => {
			setImporting(false);
			alert.success(`${promises.length} ${promises.length === 1 ? "Datensatz" : "Datensätze"} erfolgreich importiert.`);
			setShow(false);
		}).catch(error => {
			console.log(error.response);
			alert.error(`Fehler beim Import: ${error}`);
			setImporting(false);
		});
	}, [model, data, mappings, alert, ignoreFirstRow, table, setShow]);

	useEffect(() => {
		if (!model)
			return;

		setMappings(new Array(Object.keys(model.attributes).length).fill(undefined));
	}, [model]);

	return (
		<Modal show={show} size="xl" onHide={() => {}}>
			<Modal.Header>
				<Modal.Title>Daten in den Inhaltstyp "{model?.collectionName}" importieren</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{importing ? <Spinner /> :
				<>
					<p>
						Ordnen Sie hier die entsprechenden Spalten aus Ihrer <code>.csv</code> Datei den korrekten Attributen des
						Inhaltstyps zu:
					</p>
					{data[0] ?
						<section className="excel-table">
							<table>
								<thead>
									<tr>
										{Std.Range(Object.keys(model.attributes).length).map((_, index) => {
											return <th key={index}>{Std.IntToAlphabet(index)}</th>
										})}
									</tr>
								</thead>
								<tbody>
									<tr className="headline">
										{model ? Object.keys(model.attributes).map((item, index) => {
											const attr = model.attributes[item];
											return (
												<td key={index}>
													<OverlayTrigger key={index} placement="bottom" overlay={
														<Tooltip id={`tooltip-${index}`}>
															<span>Typ: {attr.hasOwnProperty("type") ? <b>{attr.type}</b> : [<b key="a">Relation</b>,<i key="b"> (zu <code key="c">{attr.model}</code></i>, ")"]}</span><br />
															Erforderlich: {attr.hasOwnProperty("required") && attr.required ? <b style={{color:"red"}}>Ja</b> : <b style={{color:"lightgreen"}}>Nein</b>}
														</Tooltip>
													}>
														<span style={{cursor : "pointer"}}>{item}</span>
													</OverlayTrigger>
												</td>
											)
										}) : <td></td>}
									</tr>
									<tr>
										{Object.keys(model.attributes).map((item, index) => (
											<td key={index}>
												<select className="form-control" value={mappings[index]} onChange={event => changeMappings(index, event.target.value)}>
													<option value="">bitte zuordnen...</option>
													{model ? data[0].map((item, index) => (
														<option value={index} key={index}>{item} [{data[1][index]}]</option>
													)) : ""}
												</select>
											</td>
										))}
									</tr>
								</tbody>
							</table>
						</section>
					: ""}
					<section>
						<Form.Check style={{display : "inline"}} type="switch" name="ignorefirstRow" onChange={() => setIgnoreFirstRow(!ignoreFirstRow)} checked={ignoreFirstRow} label="" id="ignoreFistRow" />
						<label htmlFor="ignoreFistRow" style={{userSelect:"none", cursor:"pointer"}}>Erste Zeile beim Import ignorieren <i>(Kopfzeile)</i></label>
					</section>
					<section className="alert alert-warning my-3">
						<h4>Bitte beachten Sie</h4>
						<p>
							Bereits existierende Datensätze mit überschneidenden Inhalten werden überschrieben!
						</p>
					</section>
				</>
			}
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
				<button className="btn btn-success" onClick={startImport}>Importieren</button>
			</Modal.Footer>
		</Modal>
	)
};

export default ImportModal;
