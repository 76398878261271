import Std from "../Std";

import {Hr} from "../StringIcons";

class HLineBlock {
	render() {
		return Std.CreateElement("<hr />");
	}

	save() {
		return {};
	}

	static get toolbox() {
		return {
			title : "Horizontale Linie",
			icon  : Hr
		}
	}
}

export default HLineBlock;
