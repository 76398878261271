
class StrapiDate extends Date {
	differenceInWeeks(date) {
		return Math.ceil((this - date) / (7 * 24 * 60 * 60 * 1000));
	}

	differenceInDays(date) {
		return Math.round((this - date) / (24 * 60 * 60 * 1000));
	}

	toStrapiDate() {
		return this.toString();
	}

	toJSON() {
		return this.toString();
	}

	toString() {
		return this.toLocaleString("fr-CA", {
			year  : "numeric",
			month : "2-digit",
			day   : "2-digit"
		});
	}

	addWeeks(weeks) {
		const newDate = new Date(this);
		return new Date(newDate.setDate(newDate.getDate() + (weeks * 7)));
	}
}

const GenerateSecuraPassword = () => {
	let key = "";

	let key32 = new Uint32Array(4);
	key32 = window.crypto.getRandomValues(key32);

	for (let keySegment = 0; keySegment < key32.length; keySegment++) {
		key += (keySegment > 0) ? "-" : "";
		key += key32[keySegment].toString(16);
	}

	return key;
}

export {StrapiDate, GenerateSecuraPassword};
