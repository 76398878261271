import create     from "zustand";
import axios      from "axios";
import {DateTime} from "luxon";
import { escapeRegExp } from "lodash";

import Time from "utils/Time";

const usePCStore = create((set, get) => ({
	today         : DateTime.now(),

	students      : [],
	studentsReady : false,
	studentsLoad  : alert => {
		// this is an expensive operation, so we give it a large timeout. ~~Mike
		axios.get(`/students/stats`, { timeout : 2 * Time.MINUTES}).then(response => {
			set({ students : response.data, studentsReady : true, filtered: response.data});
		}).catch(error => alert.error(`Fehler beim Laden der Schülerliste: ${error}`));
	},
	studentsFilter : showInactive => {
		const {search, course, students} = get();

		if (search === "" && course === "") {
			set({ filtered : showInactive ? students : students.filter(student => student.active === true)});
			return;
		}
		const regex = new RegExp(`${escapeRegExp(search)}`, "gi"); // search is fuzzy, because of stupid naming convention
		set({filtered : students.filter(student => {
			let select = true;
			if (!showInactive)
				select = student.active;
			if (select && search !== "")
				select = student.firstname.match(regex) || student.lastname.match(regex);
			if (select && course !== "")
				select = student.course?.id === course;

			return select;
		})});
	},
	studentsDelete : alert => {
		const {student, students} = get();
		axios.delete(`/students/${student.id}`).then(response => {
			const newStudents = students.filter(item => item.id !== student.id);
			alert.success(`Der Schüler '${student.firstname} ${student.lastname}' wurde erfolgreich gelöscht.`);
			set({students : newStudents, filtered : newStudents, student : null});
		}).catch(error => alert.error(`Fehler beim löschen des Schülers: ${error}`));
	},

	student           : null,
	studentSet        : student => set({student}),
	studentDeployment : alert => {
		const {student, today} = get();
		if (!student)
			return;

		axios.get(`/deployments?student=${student.id}&from_lt=${today.toISODate()}&to_gt=${today.toISODate()}&_sort=from:DESC`).then(response => {
			if (response.data.length < 1) {
				set({deployment : ""});

				return;
			}
			const deployments = {};
			for (const depl of response.data)
				deployments[depl.id] = depl;
			set({deployments, deployment : response.data[0]?.id});
		}).catch(error => alert.error(`Fehler beim Laden des Einsatzes: ${error}`));
	},
	studentSave        : (formData, alert) => {
		const {student, students} = get();

		if (!formData.course || formData.course === -1) {
			alert.error("Sie müssen einen Kurs auswählen");
			return;
		}

		const saveMethod = student ? axios.put : axios.post;
		const saveURL    = student ? `/students/${student.id}` : `/students`;

		saveMethod(saveURL, {...formData}).then(response => {
			const newStudent  = student ? {...formData} : response.data;
			const newStudents = student ? students.map(item => item.id === student.id ? newStudent : item) : [...students, newStudent];
			set({editShow : false, students : newStudents});
			alert.success(`Der Schüler '${formData.firstname} ${formData.lastname}' wurde ${student ? "gespeichert" : "angelegt"}.`);
		}).catch(error => alert.error(`Es ist ein Fehler beim ${student ? "Speichern" : "Anlegen"} des Schülers aufgetreten: ${error}`));
	},

	courses      : [],
	coursesReady : false,
	coursesLoad  : alert => {
		axios.get("/courses").then(response => {
			set({courses : response.data, coursesReady : true});
		}).catch(error => alert.error(`Fehler beim Laden der Kursliste: ${error}`));
	},

	deployment    : "",
	deploymentSet : deployment => set({deployment}),

	deployments   : [],

	course        : "",
	courseSet     : course => set({course}),

	filtered      : [],
	filteredSet   : filtered => set({filtered}),

	search        : "",
	searchSet     : search => set({search}),

	editShow      : false,
	editShowSet   : editShow => set({editShow})
}));

export default usePCStore;