import React from "react";

const Toggle = ({...props}) => {
	return (
		<>
			<label className="toggle">
			  <input type="checkbox" {...props} />
			  <span className="slider round"></span>
			</label>
		</>
	);
};

export default Toggle;