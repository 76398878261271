import React from "react";

const TableFooterTotalSum = ({content, colspan}) => {
	return (
		<tr style={{borderTop : "double 4px black", textAlign : "right", fontWeight : "bold"}}>
			<td colSpan={colspan}>{content}</td>
		</tr>
	);
};

export default TableFooterTotalSum;
