import React, {useState, useEffect, useCallback, useContext, useMemo} from "react";
import axios from "axios";

import {useAlert}  from "react-alert";
import UserContext from "../../../components/UserContext";
import {FolderPlus, InfoCircle, Pencil, Trash} from "react-bootstrap-icons";

import Spinner       from "../../../components/shared/Spinner";
import SortableTable from "../../../components/shared/SortableTable";
import YesNoModal    from "../../../components/shared/YesNoModal";

import CategoryModal from "./modals/CategoryModal";

const CategoriesList = () => {
	const alert                       = useAlert();
	const {permissions}               = useContext(UserContext);
	const [showDelete, setShowDelete] = useState(false);
	const [category, setCategory]     = useState(null);

	const safeDelete = useCallback((category) => {
		setUsed(0);
		axios.get(`/hour-entries/count?category=${category?.id}`).then(response => {
			setUsed(parseInt(response.data));
			setShowDelete(true);
		}).catch(error => {
			alert.error(`Fehler beim Überprüfen ob die Kategorie "${category?.name} sicher gelöscht werden kann: ${error}"`);
		});
	}, [alert]);

	const headers = useMemo(()=>[
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label  : "Name",
			member : "name"
		},
		{
			label: "",
			member: null,
			sortable: false,
			width : "100px",
			align : "right",
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {
							setCategory(member);
							setShowEdit(true);
						}}><Pencil />
						</button>
						{permissions.application.controllers.categories?.delete.enabled ?
							<button className="btn" onClick={() => {
								setCategory(member);
								safeDelete(member);
							}}><Trash/></button> : ""
						}
					</>
				)
			}
		}
	],[permissions, safeDelete]);

	const [ready, setReady]           = useState(false);
	const [showEdit, setShowEdit]     = useState(false);
	const [categories, setCategories] = useState([]);
	const [used, setUsed]             = useState(0);

	const load = useCallback(() => {
		axios.get("/categories").then(response => {
			setCategories(response.data);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Kategorien: ${error}`));
	}, [alert]);

	useEffect(() => {
		load();
	}, [load]);

	const deleteCategory = useCallback(() => {
		if (!category)
			return;
		axios.delete(`/categories/${category.id}`).then(() => {
			load();
		}).catch(error => alert.error(`Fehler beim Löschen der Kategorie '${category.name}': ${error}`))
	}, [category, alert, load]);

	if (!ready)
		return (
			<>
				<h2 className="py-4">Kategorien</h2>
				<Spinner />
			</>
		)

	const deleteText = (
		<>
			{
				used > 0 ? (
					<p className="alert alert-danger" style={{marginBottom : "2rem"}}>
						<b>Achtung:</b> Diese Kategorie wird von <code style={{color : "darkred"}}>{used}</code> Datensätzen verwendet! Das Löschen kann unerwartete Nebeneffekte haben.
					</p>
				) : ""
			}
			<p>
				{`Möchten Sie die Kategorie "${category?.name}" wirklich löschen?`}
			</p>
		</>
	);


	return (
		<>
			<h2 className="py-4">Kategorien <button className="btn btn-success btn-sm float-right" onClick={event => {setCategory(null); setShowEdit(true); }}><FolderPlus className="big" /> Kategorie anlegen</button></h2>
			{
				!permissions.application.controllers.categories?.delete.enabled ?
					<article className="alert alert-warning grid ten-ninety small-gap mb-4" style={{alignItems : "center"}}>
						<InfoCircle className="big" style={{verticalAlign : "middle"}} />
						Kategorien können aus Gründen der Datensicherheit nicht gelöscht werden. Falls Sie eine Kategorie
						versehentlich angelegt haben, wenden Sie sich bitte an die propeller GmbH
					</article> : ""
			}
			<SortableTable headers={headers} data={categories} />
			<CategoryModal show={showEdit} setShow={setShowEdit} category={category} onChange={load} />
			{
				permissions.application.controllers.categories?.delete.enabled ?
					<YesNoModal show={showDelete} setShow={setShowDelete} title="Kategorie löschen"
						text={deleteText} callback={deleteCategory} />
					: ""
			}
		</>
	)
};

export default CategoriesList;
