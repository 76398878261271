import React, {useCallback, useEffect, useMemo, useState, useRef} from "react";
import axios from "axios";

import {Modal}    from "react-bootstrap";
import {useAlert} from "react-alert";

import {StrapiDate} from "../../../../utils/StrapiUtils";
import DateInput    from "../../../../components/shared/DateInput";
import Select       from "../../../../components/shared/Select";

const today = new StrapiDate();

const DeploymentEdit = ({show, setShow, deployment, deployments, wards, onChange, student}) => {
	const alert                   = useAlert();
	const form                    = useRef();
	const [formData, setFormData] = useState({
		from       : today,
		to         : new StrapiDate(new StrapiDate().setDate(today.getDate() + 4 * 7)),
		hoursTotal : 0
	});

	const resetForm = useCallback(() => {
		setFormData({
			from       : today,
			to         : today.addWeeks(4),
			hoursTotal : 0
		});
	}, []);

	const doesOverlapWeirdly = useMemo(() => {
		const from = new StrapiDate(formData.from).getTime();
		const to   = new StrapiDate(formData.to).getTime();

		for (const depl of deployments) {
			const deplFrom = new StrapiDate(depl.from).getTime();
			const deplTo   = new StrapiDate(depl.to).getTime();
			if ((from < deplFrom && to > deplFrom && to < deplTo) || (from > deplFrom && from < deplTo && to > deplTo))
				return true;
		}

		return false;
	}, [formData, deployments]);

	const defaultHours = useMemo(() => {
		if (!formData.from || !formData.to)
			return 0;

		const weeks = new StrapiDate(formData.to).differenceInWeeks(formData.from);

		return weeks * 40;
	}, [formData.from, formData.to]);

	useEffect(() => {
		if (deployment)
			return;
		setFormData(data => ({
			...data,
			hoursTotal : defaultHours
		}));
	}, [defaultHours, deployment]);

	useEffect(() => {
		if (deployment)
			return;
		setFormData(data => ({
			...data,
			to : new StrapiDate(formData.from).addWeeks(4)
		}));
	}, [formData.from, deployment]);

	const handleInput = useCallback(event => {
		const data = {...formData};

		data[event.target.name] = event.target.value;
		setFormData(data);
	}, [formData]);

	useEffect(() => {
		if (!deployment) {
			resetForm();
			return;
		}
		setFormData({
			...deployment,
			ward : deployment.ward?.id,
			from : new StrapiDate(deployment.from),
			to   : new StrapiDate(deployment.to)
		});
	}, [deployment, resetForm]);

	const create = useCallback(() => {
		axios.post("/deployments", {
			...formData,
			student
		}).then(() => {
			onChange();
			setShow(false);
			alert.success("Der Einsatz wurde erfolgreich angelegt.")
		}).catch(error => alert.error(`Fehler beim Anlegen des Einsatze: ${error}`));
	}, [formData, student, alert, setShow, onChange]);

	const save = useCallback(() => {
		console.log("WARD",formData.ward);
		if (typeof formData.ward === "undefined" || formData.ward === "") {
			alert.error("Es muss ein Einsatzort ausgewählt werden.");
			
			return;
		}
		if (doesOverlapWeirdly) {
			alert.error("Der Einsatz würde sich auf einen nicht sinnvolle Weise mit einem anderen Einsatz überschneiden.");
			return;
		}
		if (!deployment) {
			create();
			return;
		}
		const data = {
			...formData,
			from    : new StrapiDate(formData.from),
			to      : new StrapiDate(formData.to)
		};
		axios.put(`/deployments/${deployment.id}`, data).then(() => {
			alert.success("Der Einsatz wurde erfolgreich geändert.");
			onChange();
			setShow(false);
		}).catch(error => alert.error(`Fehler beim Speichern des Einsatzes: ${error}`));
	}, [formData, deployment, alert, doesOverlapWeirdly, create, setShow, onChange]);

	return (
		<>
			<form ref={form}>
				<Modal show={show} size="lg">
					<Modal.Header>
						<Modal.Title>Einsatz {deployment ? "bearbeiten" : "anlegen"}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<section className="grid thirty-seventy">
							<label>Von</label>
							<DateInput value={formData.from} name="from" onChange={handleInput} />
							<label>Bis</label>
							<DateInput value={formData.to} name="to" onChange={handleInput} />
							<label>Einsatzort</label>
							<Select data={wards} defaultText="Einsatzort auswählen" current={formData.ward} name="ward" onChange={handleInput} required />
							<label>Sollstunden</label>
							<input type="number" className="form-control" value={formData.hoursTotal} name="hoursTotal" onChange={handleInput} />
						</section>
					</Modal.Body>
					<Modal.Footer>
							<span className="btn btn-outline-danger" onClick={() => {
								setShow(false);
							}}>Abbrechen</span>
						<button className="btn btn-success" onClick={() => save()}>{deployment ? "Speichern" : "Anlegen"}</button>
					</Modal.Footer>
				</Modal>
			</form>
		</>
	)
};

export default DeploymentEdit;
