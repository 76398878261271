import React  from "react";
import {Link} from "react-router-dom";

const LoginForm = ({reference, login, setUsername, setPassword, darkMode}) => {

	return (
		<div className="wrapper login">
			<section className="login-form" ref={reference} style={{minWidth : process.env.REACT_APP_BEPPO_USER ? "500px" : "inital"}}>
				<header>
					{darkMode ?
						<h4 className="text-center"><img src="/logo_marvolo_light.svg" alt="marvolo." /></h4>
					: <h4 className="text-center"><img src="/logo_marvolo_dark.svg" alt="marvolo." /></h4>
					}
					<p className="text-sm-center">
						<small>
							<i style={{letterSpacing : "1px"}}>enterprise resource planning, reimagined.</i>
						</small>
					</p>
				</header>
				<section className="content">
							<form onSubmit={login}>
								{ process.env.REACT_APP_LABEL_LOGIN ?
									<p>
										{process.env.REACT_APP_LABEL_LOGIN}
									</p> : ""
								}
								<p>
									<input type="text" name="username" placeholder="E-Mail" className="form-control" onChange={event => setUsername(event.target.value)} />
								</p>
								<p>
									<input type="password" name="password" placeholder="Passwort" className="form-control" onChange={event => setPassword(event.target.value)} />
								</p>
								<p>
									<button className="btn btn-primary full">Login</button>
								</p>
							</form>
						{
							process.env.REACT_APP_BEPPO_USER ?
							<form onSubmit={login}>
								{ process.env.REACT_APP_LABEL_LOGIN_GENERAL ?
								<p>
									{process.env.REACT_APP_LABEL_LOGIN_GENERAL}
								</p>
									: ""
								}
								<p>
									<input type="text" value={process.env.REACT_APP_BEPPO_USER} name="username" className="form-control invisible" disabled />
								</p>
								<p>
									<input type="password" placeholder="Passwort" className="form-control" onChange={event => setPassword(event.target.value)} />
								</p>
								<p>
									<button className="btn btn-primary full" onClick={event => setUsername(process.env.REACT_APP_BEPPO_USER)}>Allgemeines Login</button>
								</p>
							</form>
							: null
					}
				</section>
					<Link className="btn btn-sm text-primary" to="/forgot-password">neues Passwort anfordern</Link>
			</section>
		</div>
	);
};

export default LoginForm;
