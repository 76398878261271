import React from "react";

import {CardChecklist} from "react-bootstrap-icons";

const NoDeployment = () => {
	return (
		<section className="alert alert-warning grid ten-ninety small-gap" style={{alignItems : "center"}}>
			<CardChecklist className="big" />
			Dem ausgewählten Auszubildenden wurde für den ausgewählten Zeitpunkt kein Einsatz hinterlegt.
		</section>
	)
}

export default NoDeployment;
