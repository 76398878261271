import React from "react";

import {CardChecklist} from "react-bootstrap-icons";

import MinutesFormatter from "../../../formatters/MinutesFormatter";
import DateFormatter    from "formatters/DateFormatter";

const CellStudentStatistics = ({student}) => {
	if (!student.currentDeployment)
		return (
			<span className="text-warning">
				<CardChecklist style={{opacity : 1, marginRight : "0.5em"}} />
				zur Zeit kein Einsatz
			</span>
		)

	return (
		<span className={student?.statistics.actualTimeInMinutes >= (student.statistics.hoursNeededToEndOfWeek * 60) ? "text-success" : "text-danger"} title={`${student.currentDeployment?.ward?.name} (bis ${DateFormatter(student.currentDeployment?.to)})`}>
			{MinutesFormatter(student.statistics.actualTimeInMinutes)} / {student.statistics.hoursTotal}h
		</span>
	)
};

export default CellStudentStatistics;
