import React, {useState, useEffect, useImperativeHandle, forwardRef} from "react";
import {NavLink} from "react-router-dom";
import axios     from "axios";

import {Pencil, CalendarEvent, Stopwatch} from "react-bootstrap-icons";

import Spinner      from "../../../components/shared/Spinner";

import SortableTable from "../../../components/shared/SortableTable";
import DateFormatter from "../../../formatters/DateFormatter";

import Util from "../Util";

const headers = [
	{
		label  : "Einsatzort",
		member : "ward.name"
	},
	{
		label     : "von",
		member    : "from",
		formatter : DateFormatter
	},
	{
		label     : "bis",
		member    : "to",
		formatter : DateFormatter
	},
	{
		label      : "",
		member     : null,
		sortable   : false,
		width      : "50px",
		functional : ({member}) => {
			return Util.IsActiveDeployment(member) ? <Stopwatch title="läuft aktuell" className="text-success" style={{opacity : 1}} /> : null	
		}
	}
];

const DeploymentsList = forwardRef(({student, rowCallback = null}, ref) => {
	const [deployments, setDeployments] = useState([]);
	const [ready, setReady]             = useState(!student || typeof student === "undefined");
	
	useEffect(() => {
		ref.current.refresh();
	}, [student, ref]);
	
	useImperativeHandle(ref, () => ({
		refresh() {
			if (!student)
			return;
			axios.get(`/deployments?student=${student.id}&_sort=from:DESC`).then(response => {
				setDeployments(response.data);
				setReady(true);
			}).catch(error => error);
		}
	}));

	
	if (!ready)
		return <Spinner />

	if (deployments.length <= 0)
		return null;

	return (
		<>
			<p className="text-right">
				<NavLink to={`/PracticeChief/students/${student?.id}/hours`}>
					<button className="btn btn-outline-primary">
						<CalendarEvent className="big" />
						Stundeneinträge bearbeiten
					</button>
				</NavLink>&nbsp;
				<NavLink to={`/PracticeChief/students/${student?.id}/deployments`}>
					<button className="btn btn-outline-primary">
						<Pencil className="big" />
						Einsätze bearbeiten
					</button>
				</NavLink>
			</p>
			<SortableTable data={deployments} headers={headers} rowCallback={rowCallback} />
		</>
	);
});

export default DeploymentsList;
