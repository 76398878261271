import React, {useState} from "react";
import axios             from "axios";

import {useNavigate} from "react-router-dom";
import {useAlert}    from "react-alert";

import Spinner from "./shared/Spinner";

const ForgotPassword = ({darkMode}) => {
	const navigate              = useNavigate();
	const alert                 = useAlert();
	const [email, setEmail]     = useState(null);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const forgotPassword = event => {
		event.preventDefault();
		setLoading(true);

		axios.post(`/auth/forgot-password`, {
			email
		}).then(() => {
			setSuccess(true);
			setLoading(false);
			alert.success(`Sie erhalten in Kürze eine E-Mail mit einem Link zur Passwortvergabe.`);
			navigate("/")
		})
			.catch(error => {
				setLoading(false);
				alert.error(`Es ist ein Fehler beim Zurücksetzen des Passworts aufgetreten.`);
				console.log(error.response);
			});
	}

	return (
		<div className="wrapper login">
			<section className="card login-form p-5">
				<div className="card-body p-0">
					<header>
						{darkMode ?
							<h4 className="text-center"><img src="/logo_marvolo_light.svg" alt="marvolo." /></h4>
						: <h4 className="text-center"><img src="/logo_marvolo_dark.svg" alt="marvolo." /></h4>
						}
					</header>
					<div className="row">
						<div className="col-md-12 text-center">
							<h4>Passwortanforderung</h4>
							<br />
							<form onSubmit={forgotPassword}>
								<p>
									<input type="text" name="username" placeholder="E-Mail" className="form-control" onChange={event => setEmail(event.target.value)} required disabled={success} />
								</p>
								<p>
									{ loading ?
										<Spinner size="2em" style={{padding: "1em !important"}} />
									 : "" }
								</p>
								<p>
									<button className="btn btn-outline-primary mr-2" onClick={event => {event.preventDefault(); navigate("/"); }}>Abbrechen</button>
									<button className="btn btn-primary" disabled={success || loading}>
										neues Passwort anfordern
									</button>
								</p>
							</form>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ForgotPassword;
