"use strict";

const Time = Object.freeze({
	MILLISECONDS : 1,
	SECONDS      : 1000,
	MINUTES      : 60 * 1000,
	HOURS        : 60 * 60 * 1000,
	DAYS         : 24 * 60 * 60 * 1000
});

export default Time;